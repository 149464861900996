const partnersList = document.querySelector('.partners-list') as HTMLElement;

if (partnersList) {
    const partnerTabs: NodeListOf<HTMLElement> = partnersList.querySelectorAll('.partners-list__tab');
    const partnersListBlocks: NodeListOf<HTMLDivElement> = partnersList.querySelectorAll('.partners-list__block');

    partnerTabs.forEach((tab, index: number) => {
        tab.addEventListener('click', () => {
            resetTabs();
            if (index === 0) {
                partnersListBlocks.forEach((carousel) => carousel.classList.add('partners-list__block--active'));
            } else {
                partnersListBlocks[index - 1].classList.add('partners-list__block--active');
            }
            tab.classList.add('partners-list__tab--active');
        });
    });

    function resetTabs() {
        partnerTabs.forEach((tab) => {
            partnersListBlocks.forEach((carousel) => carousel.classList.remove('partners-list__block--active'));
            tab.classList.remove('partners-list__tab--active');
        });
    }
}
