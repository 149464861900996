import Glide from '@glidejs/glide';
import copyToClipboard from '../../../helpers/copyToClipboard';
import saveOrRemoveFavourite from '../../../helpers/favourites';
import { IsFavourited } from '../../../helpers/isFavourite';

const eservicesComponent: HTMLDivElement = document.querySelector('.e-services');

if (eservicesComponent) {
    const eserviceCarousels: NodeListOf<Element> = eservicesComponent.querySelectorAll('.e-services__carousel-inner');
    const direction: string = document.querySelector('html').getAttribute('dir');
    const eserviceTabs: NodeListOf<HTMLButtonElement> = eservicesComponent.querySelectorAll('.e-services__tab');
    const eserviceCarouselsContainer: NodeListOf<HTMLDivElement> =
        eservicesComponent.querySelectorAll('.e-services__carousel');
    let activeTab: number = 0;

    if (eserviceCarousels.length > 0) {
        const eserviceCarouselsArray = Array.from(eserviceCarousels) || [];

        eserviceCarouselsArray.forEach((item: HTMLElement) => {
            const glideSettings = {
                type: 'slider',
                perView: 4,
                gap: 24,
                rewind: true,
                bound: true,
                direction: direction || 'ltr',
                breakpoints: {
                    1400: {
                        perView: 3,
                    },
                    1100: {
                        perView: 2,
                        gap: 16,
                    },
                },
            };

            let glide = new Glide(item, glideSettings);

            glide.mount();
        });
    }

    const resetAll = () => {
        eserviceCarouselsContainer.forEach((carousel) => carousel.classList.remove('e-services__carousel--active'));
        eserviceTabs.forEach((tab) => tab.classList.remove('e-services__tab--active'));
    };

    const showActiveItems = (activeTab: number) => {
        resetAll();
        eserviceTabs[activeTab].classList.add('e-services__tab--active');
        eserviceCarouselsContainer[activeTab].classList.add('e-services__carousel--active');

        if (window.innerWidth <= 767.98) {
            const activeCarousel = eserviceCarouselsContainer[activeTab];
            const carouselItems = activeCarousel.querySelectorAll('.e-services__card');
            carouselItems.forEach((item, index) => {
                if (index < 4) {
                    item.classList.add('e-services__card--active');
                } else {
                    item.classList.remove('e-services__card--active');
                }
            });
        }
    };

    eserviceTabs.forEach((tab, index: number) => {
        tab.addEventListener('click', () => {
            activeTab = index;
            showActiveItems(activeTab);
        });
    });

    showActiveItems(activeTab);

    const shareButtons: NodeListOf<HTMLButtonElement> = eservicesComponent.querySelectorAll('.shareButton');
    if (shareButtons && shareButtons.length > 0) {
        shareButtons.forEach((button) => {
            button.addEventListener('click', (e) => {
                copyToClipboard(button);
            });
        });
    }

    const favButtons: NodeListOf<HTMLButtonElement> = eservicesComponent.querySelectorAll('.favouriteButton');
    if (favButtons && favButtons.length > 0) {
        favButtons.forEach((button) => {
            const dataIdAttr: string | null = button.getAttribute('data-id');
            const type: string | null = button.getAttribute('data-type');
            const favouritedIds: number[] = JSON.parse(button.getAttribute('data-ids'));

            if (dataIdAttr !== null && favouritedIds != null) {
                const dataId: number = parseInt(dataIdAttr);
                if (IsFavourited(dataId, favouritedIds)) {
                    button.classList.add('active');
                } else {
                    button.classList.remove('active');
                }

                button.addEventListener('click', (e) => {
                    console.log('small button clicked - events details');
                    saveOrRemoveFavourite(type, dataId, button);
                });
            }
        });
    }
}
