import Glide from '@glidejs/glide';
import formatDateString from '../../../helpers/formatDate';

const eventsComponents: NodeListOf<HTMLDivElement> = document.querySelectorAll('.events');

eventsComponents.forEach(eventsComponent => {
    if (eventsComponent) {
        const daysContainer: NodeListOf<HTMLDivElement> = eventsComponent.querySelectorAll('.events__days-current');
        const bulletsContainer: NodeListOf<HTMLDivElement> = eventsComponent.querySelectorAll('.glide__bullets');
        const days: NodeListOf<HTMLDivElement> = eventsComponent.querySelectorAll('.events__day');
        const dayNumbers: NodeListOf<HTMLParagraphElement> = eventsComponent.querySelectorAll('.events__day-number');
        const dates: NodeListOf<HTMLSpanElement> = eventsComponent.querySelectorAll('.events__card-text');
        const eventsCarousels: NodeListOf<Element> = eventsComponent.querySelectorAll('.events__carousel-inner');
        const direction: string = document.querySelector('html').getAttribute('dir');
        const eventTabs: NodeListOf<HTMLButtonElement> = eventsComponent.querySelectorAll('.events__tab');
        const eventCarouselsContainer: NodeListOf<HTMLDivElement> = eventsComponent.querySelectorAll('.events__carousel');
        let activeTab: number = 0;
        let activeDay: number = 0;

        if (eventsCarousels.length > 0) {
            const eventsCarouselsArray = Array.from(eventsCarousels) || [];

            const generateBullets = (carousel: HTMLElement, num: number) => {
                var bulletCount = carousel.querySelectorAll('.events__card').length - num;
                var bulletWrapper = carousel.querySelector('.glide__bullets');

                for (let index = 0; index < bulletCount; index++) {
                    const button = document.createElement('button');
                    button.className = 'glide__bullet';
                    button.setAttribute('data-glide-dir', '=' + index);

                    bulletWrapper.appendChild(button);
                }
            };

            eventsCarouselsArray.forEach((item: HTMLElement) => {
                const glideSettings = {
                    type: 'slider',
                    perView: 3,
                    gap: 24,
                    rewind: false,
                    bound: true,
                    direction: direction || 'ltr',
                    breakpoints: {
                        768: {
                            perView: 2.3,
                            gap: 16,
                        },
                        480: {
                            perView: 2.27,
                        },
                    },
                };

                const glide = new Glide(item, glideSettings);

                glide.mount();

                // remove extra bullets depending how many slides per view
                const width: number = window.innerWidth;
                let diff: number = 0;

                width > 768 ? (diff = 2) : (diff = 1);

                generateBullets(item, diff);

                glide.on('move.after', () => {
                    const bullets: NodeListOf<HTMLButtonElement> = item.querySelectorAll('.glide__bullet');
                    const currentIndex = glide.index;

                    if (bullets.length > 0) {
                        bullets.forEach((bullet, index) => {
                            bullet.classList.remove('glide__bullet--active');

                            bullet.addEventListener('click', (e) => {
                                glide.go(`=${index}`);
                            });
                        });

                        bullets[currentIndex].classList.add('glide__bullet--active');
                    }
                });
            });
        }

        const resetAll = () => {
            eventCarouselsContainer.forEach((carousel) => carousel.classList.remove('events__carousel--active'));
            daysContainer.forEach((item) => item.classList.add('hidden'));
            eventTabs.forEach((tab) => tab.classList.remove('events__tab--active'));
            days.forEach((day) => day.classList.remove('events__day--active'));
            bulletsContainer.forEach((item) => item.classList.remove('glide__bullets--active'));
        };

        const showActiveItems = (activeTab: number, activeDay: number) => {
            resetAll();
            eventTabs[activeTab].classList.add('events__tab--active');
            if (activeTab === 1) {
                eventCarouselsContainer[activeDay].classList.add('events__carousel--active');
                bulletsContainer[activeDay].classList.add('glide__bullets--active');
            } else {
                daysContainer[activeTab].classList.remove('hidden');
                days[activeDay].classList.add('events__day--active');
                eventCarouselsContainer[activeDay].classList.add('events__carousel--active');
                bulletsContainer[activeDay].classList.add('glide__bullets--active');
            }
        };

        const getDayFromDate = (dateString: string) => {
            const dateParts = dateString.split('-');
            const day = parseInt(dateParts[2], 10);

            return day.toString();
        };

        eventTabs.forEach((tab, index: number) => {
            tab.addEventListener('click', () => {
                activeTab = index;
                index === 0 ? (activeDay = 0) : (activeDay = 5);
                showActiveItems(activeTab, activeDay);
            });
        });

        days.forEach((day, index: number) => {
            day.addEventListener('click', () => {
                activeDay = index;
                showActiveItems(activeTab, activeDay);
            });
        });

        dayNumbers.forEach((day) => {
            const fullDate = day.textContent;
            day.textContent = getDayFromDate(fullDate);
        });

        showActiveItems(activeTab, activeDay);
    }
})