import { updatePaginationButtons } from '../pagination/pagination';
import { CompItem, FetchCompetitionsListingPayload, FetchCompetitionsListingResponse } from './competitionsList.types';
import axios from 'axios';
import saveOrRemoveFavourite from '../../../helpers/favourites';
import { IsFavourited } from '../../../helpers/isFavourite';
import copyToClipboard from '../../../helpers/copyToClipboard';
import IsUserLoggedIn from '../../../helpers/isUserLoggedIn';

const competitionsList: HTMLDivElement = document.querySelector('.competitions-list');

if (competitionsList) {
    const grid = competitionsList.querySelector('.competitions-list__images') as HTMLDivElement;
    const nextPageButton = competitionsList.querySelector('.pagination__page-next') as HTMLButtonElement;
    const prevPageButton = competitionsList.querySelector('.pagination__page-prev') as HTMLButtonElement;
    const paginationDropdown = competitionsList.querySelector('.pagination__dropdown') as HTMLSelectElement;
    const paginationLast = competitionsList.querySelector('.pagination__page-last') as HTMLSelectElement;
    const paginationFirst = competitionsList.querySelector('.pagination__page-first') as HTMLSelectElement;
    const pagination = competitionsList.querySelector('.pagination') as HTMLDivElement;
    const currentCulture: string = document.documentElement.getAttribute('culture');
    const favouritedIds: number[] = JSON.parse(grid.getAttribute('data-ids'));

    let compCards: CompItem[] = [];
    let pageNumber = 1;
    let pageSize = +paginationDropdown.value;
    let totalPages = 0;
    let totalCount: number = 0;
    let hasNextPage = false;
    let hasPreviousPage = false;
    let buttonsToShow: number = 3;
    let isLoading: boolean = false;
    let clickedNumber = null;

    const fetchCompetitions = async (page = null) => {
        clickedNumber = page;
        pagination.classList.add('hidden');

        if (clickedNumber) {
            pageNumber = page;
        }

        const payload: FetchCompetitionsListingPayload = {
            pageNumber,
            pageSize,
            currentCulture,
        };

        isLoading = true;

        disableInputs();

        try {
            let response;
            if (
                (window.location.hostname === 'localhost' && window.location.port === '2000') ||
                window.location.hostname === 'mawhiba-fractal.netlify.app'
            ) {
                response = await axios.post<FetchCompetitionsListingResponse>(
                    'https://app-prd-umbracoportal-uaenorth-mawhiba-development.azurewebsites.net/api/competitions',
                    payload
                );
            } else {
                response = await axios.post<FetchCompetitionsListingResponse>(`/api/competitions`, payload);
            }

            compCards = response.data.items;

            if (compCards.length > 0) {
                pagination.classList.remove('hidden');
            }

            totalCount = response.data.totalCount;
            totalPages = response.data.totalPages;
            hasNextPage = response.data.hasNextPage;
            hasPreviousPage = response.data.hasPreviousPage;
        } catch (error) {
            console.error(error);
            enableInputs();
        } finally {
            updatePaginationButtons(
                totalPages,
                hasPreviousPage,
                hasNextPage,
                pageNumber,
                pageSize,
                totalCount,
                buttonsToShow,
                compCards,
                fetchCompetitions
            );
            isLoading = false;
            updateCards();
            enableInputs();
        }
    };

    const updateCards = () => {
        grid.innerHTML = '';

        compCards.forEach((card: CompItem) => {
            const cardElement = document.createElement('div');
            cardElement.className = 'competitions-list__item sticky flex flex--column';
            cardElement.style.backgroundImage = `url(${card.backgroundImage})`;

            var _class = '';
            if (IsFavourited(card.id, favouritedIds)) {
                _class = 'active';
            }

            cardElement.innerHTML = `            
            <div class="competitions-list__item-content flex flex--column">
                <p class="competitions-list__title">${card.title}</p>
                <p class="competitions-list__text">${card.text}</p>
                <div class="competitions-list__buttons flex flex--align-center">
                    <button
                        class="button button--round-tiny shareButton competitions-list__button competitions-list__button competitions-list__button-share flex flex--justify-center flex--align-center">
                        <img src="/assets/img/img/share-filled-icon.svg" alt="share icon" class="button__image">
                    </button>
                    ${
                        IsUserLoggedIn()
                            ? `<button class="button button--favourite-small button--favourite-white competitions-list__button favouriteButton events-card__button flex flex--justify-center flex--align-center ${_class}" data-type="competition" data-id="${card.id}">
                                </button>`
                            : ''
                    }
                    
                </div>
            </div>
            <a href="${card.link}" draggable="false" ${card.target !== null ? `target=${card.target}` : ''}
                class="link link--lg link--transparent competitions-list__item-link flex flex--justify-center flex--align-center">
                <span class="link__label">${card.linkText}</span>
                <img class="link__image" src="/assets/img/img/arrow-top-left.svg" alt="more">
            </a>
            `;

            grid.appendChild(cardElement);
        });
        // hidden card to lock the last card
        const cardElement = document.createElement('div');
        cardElement.className = 'programs-list__item sticky';
        cardElement.style.opacity = '0';
        cardElement.style.zIndex = '-1';
        grid.appendChild(cardElement);

        addCardListeners();
    };

    const disableInputs = () => {
        pagination.classList.add('disabled');
    };

    const enableInputs = () => {
        pagination.classList.remove('disabled');
    };

    // Listeners
    paginationDropdown.addEventListener('change', () => {
        pageSize = +paginationDropdown.value;
        pageNumber = 1;
        fetchCompetitions();
    });

    nextPageButton.addEventListener('click', () => {
        pageNumber++;
        fetchCompetitions();
    });

    prevPageButton.addEventListener('click', () => {
        pageNumber--;
        fetchCompetitions();
    });

    paginationFirst.addEventListener('click', () => {
        pageNumber = 1;
        fetchCompetitions();
    });

    paginationLast.addEventListener('click', () => {
        pageNumber = totalPages;
        fetchCompetitions();
    });

    fetchCompetitions();

    const addCardListeners = () => {
        const listCards: NodeListOf<HTMLAnchorElement> = competitionsList.querySelectorAll('.competitions-list__item');

        if (listCards.length > 0) {
            const cardButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.competitions-list__button');

            listCards.forEach((card) => {
                card.addEventListener('click', (e) => {
                    const target = e.target as HTMLElement;

                    if (target.classList.contains('button') || target.classList.contains('button__image')) {
                        e.preventDefault();
                    }
                });
            });

            cardButtons.forEach((button) => {
                button.addEventListener('click', (e) => {
                    if (button.classList.contains('favouriteButton')) {
                        const t = button.getAttribute('data-type');
                        var id = Number(button.getAttribute('data-id'));
                        saveOrRemoveFavourite(t, id, button);
                    } else if (button.classList.contains('shareButton')) {
                        copyToClipboard(button);
                    }
                });
            });

            let top: number = 180;
            const headerHeight: number = 65;
            if (window.innerWidth < 1200) {
                top = 20;
            }
            const cardsCount: number = listCards.length;

            if (cardsCount) {
                listCards.forEach((card, i) => {
                    const incValue: number = i * headerHeight;

                    if (i === 0) {
                        card.style.top = `${top}px`;
                    } else {
                        card.style.top = `${top + incValue}px`;
                    }
                });
            }
        }
    };
}
