const navbar: HTMLHeadElement = document.querySelector('.navbar');

if (navbar) {
    const navbarUser = navbar.querySelector('.navbar__user') as HTMLDivElement;
    const navbarUserDropdown = navbar.querySelector('.navbar__user-dropdown') as HTMLUListElement;
    const searchButton = navbar.querySelector('.navbar__button-search') as HTMLButtonElement;
    const linkItems: NodeListOf<HTMLLIElement> = navbar.querySelectorAll('.navbar__links-drop');
    const searchInput = navbar.querySelector('.navbar__button-search-input') as HTMLInputElement;
    const searchPageUrl = searchInput.parentElement.getAttribute('data-search-page') as string;
    const searchSubmitButton = navbar.querySelector('.navbar__button-search-submit') as HTMLButtonElement;

    const checkScrollPosition = () => {
        if (window.scrollY === 0) {
            navbar.classList.remove('navbar--sticky');
        } else {
            navbar.classList.add('navbar--sticky');
        }
    };

    const openSearchDropdown = () => {
        searchButton.nextElementSibling.classList.add('navbar__button-search-inner--active');
    };

    const closeSearchDropdown = () => {
        searchButton.nextElementSibling.classList.remove('navbar__button-search-inner--active');
    };

    searchInput.addEventListener('input', () => {
        if (searchInput.value.length > 0) {
            searchSubmitButton.removeAttribute('disabled');
        } else {
            searchSubmitButton.setAttribute('disabled', 'true');
        }
    });

    searchInput.addEventListener('submit', (e) => {
        e.preventDefault();
        if (searchInput.value.length > 0) {
            window.location.href = searchPageUrl + '?q=' + searchInput.value;
        }
    });

    searchSubmitButton.addEventListener('click', (e) => {
        e.preventDefault();
        if (searchInput.value.length > 0) {
            window.location.href = searchPageUrl + '?q=' + searchInput.value;
        }
    });

    if (navbarUser) {
        navbarUser.addEventListener('click', (event: MouseEvent) => {
            event.stopPropagation();
            navbarUserDropdown.classList.toggle('navbar__user-dropdown--active');
        });

        navbarUser.addEventListener('keydown', (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                event.stopPropagation();
                navbarUserDropdown.classList.toggle('navbar__user-dropdown--active');
            }
        });
    }

    linkItems.forEach((link) => {
        const href: string = link.getAttribute('data-url');

        link.addEventListener('click', () => {
            if (href) {
                window.location.href = href;
            }
        });

        link.addEventListener('keydown', (e: KeyboardEvent) => {
            if (e.key === 'Enter' && href) {
                window.location.href = href;
            }
        });
    });

    window.addEventListener('click', (event: MouseEvent) => {
        const targetElement = navbar.querySelector('.navbar__button-search-inner');
        let clickedElement = event.target as Element;

        if (!clickedElement.classList.contains('navbar__user') || !clickedElement.parentElement.classList.contains('navbar__user')) {
            if(navbarUserDropdown!=null)
            {
                navbarUserDropdown.classList.remove('navbar__user-dropdown--active');
            }
        }

        if (clickedElement.classList.contains('navbar__button-search')) {
            if (clickedElement.nextElementSibling.classList.contains('navbar__button-search-inner--active')) {
                closeSearchDropdown();
            } else {
                openSearchDropdown();
            }
            return;
        }

        if (!targetElement) return;

        while (clickedElement) {
            if (clickedElement === targetElement) {
                searchButton.nextElementSibling.classList.add('navbar__button-search-inner--active');
                return;
            }
            clickedElement = clickedElement.parentElement;
        }

        searchButton.nextElementSibling.classList.remove('navbar__button-search-inner--active');
    });

    window.addEventListener('keydown', (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            closeSearchDropdown();

            navbarUserDropdown.classList.remove('navbar__user-dropdown--active');
        }
    });

    window.addEventListener('scroll', checkScrollPosition);

    checkScrollPosition();
}
