import {
    EventListCard,
    FetchEventsListPayload,
    FetchEventsListResponse,
    FetchEventCardSettingsResponse,
    ServiceCard,
    FetchServiceCardSettingsResponse,
    FetchServicesListingResponse,
    FetchServicesListingPayload,
    ProgramItem,
    FetchProgramsListingPayload,
    FetchProgramsListingResponse,
} from './favourites.types';

import formatDateString from '../../../helpers/formatDate';
import saveOrRemoveFavourite from '../../../helpers/favourites';
import { IsFavourited } from '../../../helpers/isFavourite';
import { updatePaginationButtons } from '../pagination/pagination';
import axios from 'axios';
import copyToClipboard from '../../../helpers/copyToClipboard';
import addToCalendar from '../../../helpers/addToCalendar';

const initializeFavouritesComponent = async (favouritesComponent: HTMLElement) => {
    const favouritesTabs = favouritesComponent.querySelectorAll('.favourites__tab');
    const services = favouritesComponent.querySelector('.favourites__grid--services') as HTMLElement;
    const programs = favouritesComponent.querySelector('.favourites__grid--programs') as HTMLElement;
    const compeitions = favouritesComponent.querySelector('.favourites__grid--contests') as HTMLElement;
    const events = favouritesComponent.querySelector('.favourites__grid--events') as HTMLElement;
    let activeTab = 0;

    const nextPageButton = favouritesComponent.querySelector('.pagination__page-next') as HTMLButtonElement;
    const prevPageButton = favouritesComponent.querySelector('.pagination__page-prev') as HTMLButtonElement;
    const paginationDropdown = favouritesComponent.querySelector('.pagination__dropdown') as HTMLSelectElement;
    const paginationLast = favouritesComponent.querySelector('.pagination__page-last') as HTMLSelectElement;
    const paginationFirst = favouritesComponent.querySelector('.pagination__page-first') as HTMLSelectElement;
    const pagination = favouritesComponent.querySelector('.pagination') as HTMLDivElement;
    const radios: NodeListOf<HTMLInputElement> = favouritesComponent.querySelectorAll('.sort-filter__input');
    const currentCulture: string = document.documentElement.getAttribute('culture');

    let eventCards: EventListCard[] = [];
    let servicesCards: ServiceCard[] = [];
    let programsCards: ProgramItem[] = [];
    let competitionCards: ProgramItem[] = [];
    let ids: number[] = [];
    const sortBy: number = 1;
    let pageNumber: number = 1;
    let pageSize: number = +paginationDropdown.value;
    let totalPages: number = 0;
    let totalCount: number = 0;
    let hasNextPage: boolean = false;
    let hasPreviousPage: boolean = false;
    const buttonsToShow: number = 3;
    let isLoading: boolean = false;
    let currentType: string = 'services';

    let eventCardSettings: FetchEventCardSettingsResponse = {
        dateFromText: '',
        dateToText: '',
        locationText: '',
    };

    const fetchEventCardSettings = async () => {
        try {
            const response = await axios.get<FetchEventCardSettingsResponse>(
                `/api/events/cardsettings?currentCulture=${currentCulture}`
            );

            eventCardSettings = response.data;
        } catch (error) {
            console.error(error);
        }
    };

    let serviceCardSettings: FetchServiceCardSettingsResponse = {
        cardButtonText: '',
        availableText: '',
        endText: '',
    };

    const fetchServiceCardSettings = async () => {
        try {
            const response = await axios.get<FetchServiceCardSettingsResponse>(
                `/api/services/cardsettings?currentCulture=${currentCulture}`
            );

            serviceCardSettings = response.data;
        } catch (error) {
            console.error(error);
        }
    };

    // Set tab names
    const TabTranslations = {
        services: { ar: 'الخدمات الإلكترونية', engb: 'Electric services' },
        programs: { ar: 'البرامج', engb: 'Programs' },
        contests: { ar: 'المسابقات', engb: 'Contests' },
        events: { ar: 'الفعاليات', engb: 'Events' },
    };

    const languageKey = document.querySelector('html')?.getAttribute('dir') === 'rtl' ? 'ar' : 'engb';
    const tabTitles = favouritesComponent.querySelectorAll('.favourites__tab-title');

    tabTitles.forEach((title) => {
        const alias = title.textContent?.trim().toLowerCase();
        if (TabTranslations[alias]) {
            title.textContent = TabTranslations[alias][languageKey];
        }
    });

    // Tab logic
    const showSelectedItems = async (activeTab: number) => {
        favouritesTabs.forEach((tab) => tab.classList.remove('favourites__tab--active'));
        const selectedTab = favouritesTabs[activeTab];
        selectedTab.classList.add('favourites__tab--active');
        currentType = selectedTab.getAttribute('data-type');
        ids = JSON.parse(selectedTab.getAttribute('data-ids'));

        // get cards from API
        pageNumber = 1; // go to the begining of the pages

        if (currentType == 'event') {
            getEventCards();
        } else if (currentType == 'service') {
            getServiceCards();
        } else if (currentType == 'competition') {
            getCompetitionCards();
        } else if (currentType == 'program') {
            getProgramCards();
        }

        const grids = [services, programs, compeitions, events];
        grids.forEach((grid, index) => {
            grid.style.display = activeTab === index ? 'grid' : 'none';
        });
    };

    const handleTabClick = (index: number) => {
        activeTab = index;
        showSelectedItems(activeTab);
    };

    favouritesTabs.forEach((tab, index) => {
        tab.addEventListener('click', () => handleTabClick(index));
    });

    const getEventCards = async () => {
        if (ids.length > 0) {
            const payload: FetchEventsListPayload = {
                sortBy,
                pageNumber,
                pageSize,
                currentCulture,
                ids,
            };

            isLoading = true;

            disableInputs();

            try {
                let response;
                if (
                    (window.location.hostname === 'localhost' && window.location.port === '2000') ||
                    window.location.hostname === 'mawhiba-fractal.netlify.app'
                ) {
                    response = await axios.post<FetchEventsListResponse>(
                        'https://app-prd-umbracoportal-uaenorth-mawhiba-development.azurewebsites.net/api/events/byids',
                        payload
                    );
                } else {
                    response = await axios.post<FetchEventsListResponse>('/api/events/byids', payload);
                }
                eventCards = response.data.items;
                if (eventCards.length > 0) {
                    pagination.classList.remove('hidden');
                }
                totalPages = response.data.totalPages;
                totalCount = response.data.totalCount;
                hasNextPage = response.data.hasNextPage;
                hasPreviousPage = response.data.hasPreviousPage;
            } catch (error) {
                console.error(error);
                enableInputs();
            } finally {
                updatePaginationButtons(
                    totalPages,
                    hasPreviousPage,
                    hasNextPage,
                    pageNumber,
                    pageSize,
                    totalCount,
                    buttonsToShow,
                    eventCards,
                    getEventCards
                );
                isLoading = false;
                updateEventCards();
                enableInputs();
            }
        } else {
            pagination.classList.add('hidden');
            events.innerHTML = '';
        }
    };

    const getServiceCards = async () => {
        if (ids.length > 0) {
            const payload: FetchServicesListingPayload = {
                sortBy,
                pageNumber,
                pageSize,
                currentCulture,
                ids,
            };

            isLoading = true;
            disableInputs();

            try {
                let response;
                if (
                    (window.location.hostname === 'localhost' && window.location.port === '2000') ||
                    window.location.hostname === 'mawhiba-fractal.netlify.app'
                ) {
                    response = await axios.post<FetchServicesListingResponse>(
                        'https://app-prd-umbracoportal-uaenorth-mawhiba-development.azurewebsites.net/api/services/byids',
                        payload
                    );
                } else {
                    response = await axios.post<FetchServicesListingResponse>('/api/services/byids', payload);
                }

                servicesCards = response.data.items;

                if (servicesCards.length > 0) {
                    pagination.classList.remove('hidden');
                }

                totalCount = response.data.totalCount;
                totalPages = response.data.totalPages;
                hasNextPage = response.data.hasNextPage;
                hasPreviousPage = response.data.hasPreviousPage;
            } catch (error) {
                console.error(error);
                enableInputs();
            } finally {
                updatePaginationButtons(
                    totalPages,
                    hasPreviousPage,
                    hasNextPage,
                    pageNumber,
                    pageSize,
                    totalCount,
                    buttonsToShow,
                    servicesCards,
                    getServiceCards
                );
                isLoading = false;
                updateServiceCards();
                enableInputs();
            }
        } else {
            pagination.classList.add('hidden');
            services.innerHTML = '';
        }
    };

    const getProgramCards = async () => {
        if (ids.length > 0) {
            const payload: FetchProgramsListingPayload = {
                pageNumber,
                pageSize,
                currentCulture,
                ids,
            };

            isLoading = true;

            disableInputs();

            try {
                let response;
                if (
                    (window.location.hostname === 'localhost' && window.location.port === '2000') ||
                    window.location.hostname === 'mawhiba-fractal.netlify.app'
                ) {
                    response = await axios.post<FetchProgramsListingResponse>(
                        'https://app-prd-umbracoportal-uaenorth-mawhiba-development.azurewebsites.net/api/programs/byids',
                        payload
                    );
                } else {
                    response = await axios.post<FetchProgramsListingResponse>(`/api/programs/byids`, payload);
                }

                programsCards = response.data.items;

                if (programsCards.length > 0) {
                    pagination.classList.remove('hidden');
                }

                totalCount = response.data.totalCount;
                totalPages = response.data.totalPages;
                hasNextPage = response.data.hasNextPage;
                hasPreviousPage = response.data.hasPreviousPage;
            } catch (error) {
                console.error(error);
                enableInputs();
            } finally {
                updatePaginationButtons(
                    totalPages,
                    hasPreviousPage,
                    hasNextPage,
                    pageNumber,
                    pageSize,
                    totalCount,
                    buttonsToShow,
                    programsCards,
                    getProgramCards
                );
                isLoading = false;
                updateProgramCards();
                enableInputs();
            }
        } else {
            pagination.classList.add('hidden');
            programs.innerHTML = '';
        }
    };

    const getCompetitionCards = async () => {
        if (ids.length > 0) {
            const payload: FetchProgramsListingPayload = {
                pageNumber,
                pageSize,
                currentCulture,
                ids,
            };

            isLoading = true;

            disableInputs();

            try {
                let response;
                if (
                    (window.location.hostname === 'localhost' && window.location.port === '2000') ||
                    window.location.hostname === 'mawhiba-fractal.netlify.app'
                ) {
                    response = await axios.post<FetchProgramsListingResponse>(
                        'https://app-prd-umbracoportal-uaenorth-mawhiba-development.azurewebsites.net/api/competitions/byids',
                        payload
                    );
                } else {
                    response = await axios.post<FetchProgramsListingResponse>(`/api/competitions/byids`, payload);
                }

                competitionCards = response.data.items;

                if (competitionCards.length > 0) {
                    pagination.classList.remove('hidden');
                }

                totalCount = response.data.totalCount;
                totalPages = response.data.totalPages;
                hasNextPage = response.data.hasNextPage;
                hasPreviousPage = response.data.hasPreviousPage;
            } catch (error) {
                console.error(error);
                enableInputs();
            } finally {
                updatePaginationButtons(
                    totalPages,
                    hasPreviousPage,
                    hasNextPage,
                    pageNumber,
                    pageSize,
                    totalCount,
                    buttonsToShow,
                    competitionCards,
                    getCompetitionCards
                );
                isLoading = false;
                updateCompetitionCards();
                enableInputs();
            }
        } else {
            pagination.classList.add('hidden');
            compeitions.innerHTML = '';
        }
    };

    const updateEventCards = () => {
        events.innerHTML = '';

        eventCards.forEach((card: EventListCard) => {
            const cardElement = document.createElement('a');

            cardElement.setAttribute('href', card.link);
            cardElement.className = 'events-card flex fade-in';

            var _class = '';
            if (IsFavourited(card.id, ids)) {
                _class = 'active';
            }

            cardElement.innerHTML = `
                <div class="events-card__image-container">
                <img src="${card.image}" alt="" class="events-card__image">
                </div>
                <div class="events-card__content flex flex--column">
                <div class="events-card__buttons flex flex--align-center flex--justify-end">

                    <button class="button button--favourite-small favouriteButton events-card__button flex flex--justify-center flex--align-center ${_class}" data-type="event" data-id="${
                card.id
            }">
                    </button>
                    <button class="button  button--round-small  events-card__button events-card__button-share  flex flex--justify-center flex--align-center shareButton">
                        <img src="/assets/img/img/share-icon.svg" alt="share icon" class="button__image">
                    </button>

                    <button class="button  button--round-small calButton events-card__button events-card__button-calendar  flex flex--justify-center flex--align-center">
                        <img src="/assets/img/img/calendar-add.svg" alt="calendar icon" class="button__image">
                    </button>

                </div>
                    <h3 class="events-card__title">${card.title}</h3>
                    <div class="events-card__bottom flex flex--wrap flex--justify-between flex--align-center">
            
                    <div class="events-card__column flex flex--column">
                    <p class="events-card__column-title">${eventCardSettings.dateFromText}</p>
                    <p class="events-card__column-text">${formatDateString(card.start_Date)}</p>
                </div>

                <div class="events-card__column flex flex--column">
                    <p class="events-card__column-title">${eventCardSettings.dateToText}</p>
                    <p class="events-card__column-text">${formatDateString(card.end_Date)}</p>
                </div>
                ${
                    card.location !== ''
                        ? `<div class="events-card__column flex flex--column">
                            <p class="events-card__column-title">${eventCardSettings.locationText}</p>
                            <p class="events-card__column-text">${card.location}</p>
                            </div>`
                        : ''
                }
                </div>
                </div>
                `;

            events.appendChild(cardElement);
        });
        addEventCardListeners();
    };

    const updateServiceCards = () => {
        services.innerHTML = '';

        servicesCards.forEach((card: ServiceCard) => {
            const cardElement = document.createElement('a');
            cardElement.className = 'service-list__card fade-in';
            cardElement.setAttribute('href', card.link);

            var _class = '';
            if (IsFavourited(card.id, ids)) {
                _class = 'active';
            }

            cardElement.innerHTML = `
                <p class='service-list__card-tag'>${
                    card.isAvailable ? serviceCardSettings.availableText : serviceCardSettings.endText
                }</p>
				<h3 class='service-list__card-title'>${card.title}</h3>
				<p class='service-list__card-text'>
						${card.description}
				</p>
				<div class='service-list__card-bottom flex flex--justify-between flex--align-center'>
                        <div class="service-list__link flex flex--align-center flex--justify-center">
                            ${serviceCardSettings.cardButtonText}
                            <img src="/assets/img/img/arrow-top-left-dark.svg" alt="arrow icon"
                                class="service-list__link-icon">
                        </div>
					<div class='service-list__card-buttons flex flex--align-center'>
                        <button class="button button--favourite-small favouriteButton service-list__button flex flex--justify-center flex--align-center ${_class}" data-type="service" data-id="${
                card.id
            }">
                        </button>
						<button class="button  button--round-small  service-list__button-share service-list__button  flex flex--justify-center flex--align-center shareButton">
								<img src="/assets/img/img/share-icon.svg" alt="share icon" class="button__image">
						</button>
					</div>
				</div>
				`;

            services.appendChild(cardElement);
        });

        addServiceCardListeners();
    };

    const updateProgramCards = () => {
        programs.innerHTML = '';

        programsCards.forEach((card: ProgramItem) => {
            const cardElement = document.createElement('div');
            cardElement.className = 'programs-list__item flex flex--column';
            cardElement.style.backgroundImage = `url(${card.backgroundImage})`;

            var _class = '';
            if (IsFavourited(card.id, ids)) {
                _class = 'active';
            }

            cardElement.innerHTML = `            
            <div class="programs-list__item-content flex flex--column">
                <p class="programs-list__title">${card.title}</p>
                <p class="programs-list__text">${card.text}</p>
                <div class="programs-list__buttons flex flex--align-center">
                    <button
                        class="button button--round-tiny programs-list__button programs-list__button programs-list__button-share flex flex--justify-center flex--align-center shareButton">
                        <img src="/assets/img/img/share-filled-icon.svg" alt="share icon" class="button__image">
                    </button>
                    <button class="button button--favourite-white button--favourite-small favouriteButton programs-list__button flex flex--justify-center flex--align-center" data-type="program" data-id="${card.id}">
                    </button>
                </div>
            </div>
            <a href="${card.link}" draggable="false"
                class="link link--lg link--transparent programs-list__item-link flex flex--justify-center flex--align-center">
                <span class="link__label">${card.linkText}</span>
                <img class="link__image" src="/assets/img/img/arrow-top-left.svg" alt="more">
            </a>
            `;

            programs.appendChild(cardElement);
        });

        addProgramCardListeners();
    };

    const updateCompetitionCards = () => {
        compeitions.innerHTML = '';

        competitionCards.forEach((card: ProgramItem) => {
            const cardElement = document.createElement('div');
            cardElement.className = 'programs-list__item flex flex--column';
            cardElement.style.backgroundImage = `url(${card.backgroundImage})`;

            var _class = '';
            if (IsFavourited(card.id, ids)) {
                _class = 'active';
            }

            cardElement.innerHTML = `            
            <div class="programs-list__item-content flex flex--column">
                <p class="programs-list__title">${card.title}</p>
                <p class="programs-list__text">${card.text}</p>
                <div class="programs-list__buttons flex flex--align-center">
                    <button
                        class="button button--round-tiny programs-list__button programs-list__button programs-list__button-share flex flex--justify-center flex--align-center shareButton">
                        <img src="/assets/img/img/share-filled-icon.svg" alt="share icon" class="button__image">
                    </button>
                    <button class="button button--favourite-white button--favourite-small favouriteButton programs-list__button flex flex--justify-center flex--align-center" data-type="competition" data-id="${card.id}">
                    </button>
                </div>
            </div>
            <a href="${card.link}" draggable="false"
                class="link link--lg link--transparent programs-list__item-link flex flex--justify-center flex--align-center">
                <span class="link__label">${card.linkText}</span>
                <img class="link__image" src="/assets/img/img/arrow-top-left.svg" alt="more">
            </a>
            `;

            compeitions.appendChild(cardElement);
        });

        addCompetitionCardListeners();
    };

    const addServiceCardListeners = () => {
        const serviceListCards: NodeListOf<HTMLAnchorElement> = services.querySelectorAll('.service-list__card');

        if (serviceListCards.length > 0) {
            const cardButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.service-list__button');

            serviceListCards.forEach((card) => {
                card.addEventListener('click', (e) => {
                    const target = e.target as HTMLElement;

                    if (target.classList.contains('button') || target.classList.contains('button__image')) {
                        e.preventDefault();
                    }
                });
            });

            cardButtons.forEach((button) => {
                button.addEventListener('click', (e) => {
                    if (button.classList.contains('favouriteButton')) {
                        const t = button.getAttribute('data-type');
                        var id = Number(button.getAttribute('data-id'));
                        saveOrRemoveFavourite(t, id, button);
                        ids = ids.filter((itemId) => itemId !== id);
                        fetchCards();
                    } else if (button.classList.contains('shareButton')) {
                        copyToClipboard(button);
                    } else if (button.classList.contains('calButton')) {
                        addToCalendar(button);
                    }
                });
            });
        }
    };

    const addProgramCardListeners = () => {
        const listCards: NodeListOf<HTMLAnchorElement> = programs.querySelectorAll('.programs-list__item');

        if (listCards.length > 0) {
            const cardButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.programs-list__button');

            listCards.forEach((card) => {
                card.addEventListener('click', (e) => {
                    const target = e.target as HTMLElement;

                    if (target.classList.contains('button') || target.classList.contains('button__image')) {
                        e.preventDefault();
                    }
                });
            });

            cardButtons.forEach((button) => {
                button.addEventListener('click', (e) => {
                    if (button.classList.contains('favouriteButton')) {
                        const t = button.getAttribute('data-type');
                        var id = Number(button.getAttribute('data-id'));
                        saveOrRemoveFavourite(t, id, button);
                        ids = ids.filter((itemId) => itemId !== id);
                        fetchCards();
                    } else if (button.classList.contains('shareButton')) {
                        copyToClipboard(button);
                    } else if (button.classList.contains('calButton')) {
                        addToCalendar(button);
                    }
                });
            });
        }
    };

    const addCompetitionCardListeners = () => {
        const listCards: NodeListOf<HTMLAnchorElement> = compeitions.querySelectorAll('.programs-list__item');

        if (listCards.length > 0) {
            const cardButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.programs-list__button');

            listCards.forEach((card) => {
                card.addEventListener('click', (e) => {
                    const target = e.target as HTMLElement;

                    if (target.classList.contains('button') || target.classList.contains('button__image')) {
                        e.preventDefault();
                    }
                });
            });

            cardButtons.forEach((button) => {
                button.addEventListener('click', (e) => {
                    if (button.classList.contains('favouriteButton')) {
                        const t = button.getAttribute('data-type');
                        var id = Number(button.getAttribute('data-id'));
                        saveOrRemoveFavourite(t, id, button);
                        ids = ids.filter((itemId) => itemId !== id);
                        fetchCards();
                    } else if (button.classList.contains('shareButton')) {
                        copyToClipboard(button);
                    } else if (button.classList.contains('calButton')) {
                        addToCalendar(button);
                    }
                });
            });
        }
    };

    const addEventCardListeners = () => {
        const serviceListCards: NodeListOf<HTMLAnchorElement> = events.querySelectorAll('.events-card');

        if (serviceListCards.length > 0) {
            const cardButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.events-card__button');

            serviceListCards.forEach((card) => {
                card.addEventListener('click', (e) => {
                    const target = e.target as HTMLElement;

                    if (target.classList.contains('button') || target.classList.contains('button__image')) {
                        e.preventDefault();
                    }
                });
            });

            cardButtons.forEach((button) => {
                button.addEventListener('click', (e) => {
                    if (button.classList.contains('favouriteButton')) {
                        const t = button.getAttribute('data-type');
                        var id = Number(button.getAttribute('data-id'));
                        saveOrRemoveFavourite(t, id, button);
                        ids = ids.filter((itemId) => itemId !== id);
                        fetchCards();
                    } else if (button.classList.contains('shareButton')) {
                        copyToClipboard(button);
                    } else if (button.classList.contains('calButton')) {
                        addToCalendar(button);
                    }
                });
            });
        }
    };

    const disableInputs = () => {
        pagination.classList.add('disabled');
    };

    const enableInputs = () => {
        pagination.classList.remove('disabled');
    };

    // Listeners
    paginationDropdown.addEventListener('change', () => {
        pageSize = +paginationDropdown.value;
        pageNumber = 1;
        fetchCards();
    });

    nextPageButton.addEventListener('click', () => {
        pageNumber++;

        fetchCards();
    });

    prevPageButton.addEventListener('click', () => {
        pageNumber--;

        fetchCards();
    });

    paginationFirst.addEventListener('click', () => {
        pageNumber = 1;
        fetchCards();
    });

    paginationLast.addEventListener('click', () => {
        pageNumber = totalPages;
        fetchCards();
    });

    const fetchCards = () => {
        if (currentType == 'event') {
            getEventCards();
        } else if (currentType == 'service') {
            getServiceCards();
        } else if (currentType == 'competition') {
            getCompetitionCards();
        } else if (currentType == 'program') {
            getProgramCards();
        }
    };

    await fetchServiceCardSettings();
    fetchEventCardSettings();
    await showSelectedItems(activeTab);
};

document.querySelectorAll('.favourites').forEach((favouritesComponent) => {
    initializeFavouritesComponent(favouritesComponent as HTMLElement);
});
