import {
    ServiceCard,
    FetchServiceCardSettingsResponse,
    FetchServicesListingResponse,
    FetchServicesListingPayload,
} from './navbarFavourites.types';
import axios from 'axios';

const initializeFavouritesNavComponent = (favouritesTabComponent: HTMLElement) => {
    const favouritesTabs: NodeListOf<HTMLElement> = favouritesTabComponent.querySelectorAll('.nav-favourites__tab');
    const services = favouritesTabComponent.querySelector('.nav-favourites__content_services') as HTMLElement;
    const others = favouritesTabComponent.querySelector('.nav-favourites__content_other') as HTMLElement;

    const favouritesContents: NodeListOf<HTMLElement> =
        favouritesTabComponent.querySelectorAll('.nav-favourites__content');
    let activeTab = 0;
    let currentType: string = 'service';
    let isLoading: boolean = false;
    const currentCulture: string = document.documentElement.getAttribute('culture');
    // let otherCards: OtherCard[] = [];
    let servicesCards: ServiceCard[] = [];
    let ids: number[] = [];

    if (favouritesTabComponent) {
        const favouritesButton = favouritesTabComponent.querySelector('.nav-favourites__button') as HTMLButtonElement;

        const hasFavourites = favouritesTabComponent.getAttribute('data-has-favourites')?.trim().toLowerCase();

        if (hasFavourites === 'true') {
            favouritesButton.classList.add('active');
        }

        const openFavouriteDropdown = () => {
            favouritesButton.nextElementSibling.classList.add('nav-favourites__inner--active');
            showSelectedTab(activeTab);
        };
        const closeFavouriteDropdown = () => {
            favouritesButton.nextElementSibling.classList.remove('nav-favourites__inner--active');
        };

        window.addEventListener('click', (event: MouseEvent) => {
            const targetElement = favouritesTabComponent.querySelector('.nav-favourites__inner');
            let clickedElement = event.target as Element;

            if (clickedElement.classList.contains('nav-favourites__button')) {
                if (clickedElement.nextElementSibling.classList.contains('nav-favourites__inner--active')) {
                    closeFavouriteDropdown();
                } else {
                    openFavouriteDropdown();
                }
                return;
            }

            if (!targetElement) return;

            while (clickedElement) {
                if (clickedElement === targetElement) {
                    favouritesButton.nextElementSibling.classList.add('nav-favourites__inner--active');
                    return;
                }
                clickedElement = clickedElement.parentElement;
            }

            favouritesButton.nextElementSibling.classList.remove('nav-favourites__inner--active');
        });

        window.addEventListener('keydown', (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                closeFavouriteDropdown();
            }
        });

        const showSelectedTab = (activeTab: number) => {
            favouritesTabs.forEach((tab, index) => {
                tab.classList.remove('nav-favourites__tab--active');
                favouritesContents[index].style.display = 'none';
            });

            const selectedTab = favouritesTabs[activeTab];

            selectedTab.classList.add('nav-favourites__tab--active');
            favouritesContents[activeTab].style.display = 'block';

            currentType = selectedTab.getAttribute('data-type');

            ids = JSON.parse(selectedTab.getAttribute('data-ids'));
            if (currentType == 'other') {
                getOtherCards();
            } else if (currentType == 'service') {
                getServiceCards();
            }
        };

        const handleTabClick = (index: number) => {
            activeTab = index;
            showSelectedTab(activeTab);
        };

        favouritesTabs.forEach((tab, index) => {
            tab.addEventListener('click', () => handleTabClick(index));
        });

        const getServiceCards = async () => {
            if (ids.length > 0) {
                const payload: FetchServicesListingPayload = {
                    sortBy: 1,
                    pageNumber: 1,
                    pageSize: 3,
                    currentCulture,
                    ids,
                };

                isLoading = true;

                try {
                    let response;
                    if (
                        (window.location.hostname === 'localhost' && window.location.port === '2000') ||
                        window.location.hostname === 'mawhiba-fractal.netlify.app'
                    ) {
                        response = await axios.post<FetchServicesListingResponse>(
                            'https://app-prd-umbracoportal-uaenorth-mawhiba-development.azurewebsites.net/api/services/byids',
                            payload,
                        );
                    } else {
                        response = await axios.post<FetchServicesListingResponse>('/api/services/byids', payload);
                    }
                    servicesCards = response.data.items;
                } catch (error) {
                    console.error(error);
                } finally {
                    isLoading = false;
                    updateServiceCards();
                }
            } else {
                // TODO - GET THIS TEXT
                //services.innerHTML = `<div class='nav-favourites__item-empty'>${noFavouritesText}</div>`;
            }
        };

        const getOtherCards = async () => { };

        const updateServiceCards = () => {
            services.innerHTML = '';

            servicesCards.forEach((card: ServiceCard) => {
                const cardElement = document.createElement('a');
                cardElement.className = 'nav-favourites__item flex flex--align-center flex--justify-between';
                cardElement.setAttribute('href', card.link);

                cardElement.innerHTML = `
                <div class='nav-favourites__item-left flex flex--align-center'>
                        <div class='nav-favourites__item-title'>${card.title}</div>
                </div>
                <img src="/assets/img/img/arrow-top-left-dark.svg" alt="arrow icon" class="nav-favourites__item-arrow">
                `;

                services.appendChild(cardElement);
            });
        };

        showSelectedTab(activeTab);
    }
};

document.querySelectorAll('.nav-favourites__wrapper').forEach((favouritesTabComponent) => {
    initializeFavouritesNavComponent(favouritesTabComponent as HTMLElement);
});
