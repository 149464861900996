import {
    FetchServiceCardSettingsResponse,
    FetchServicesListingPayload,
    FetchServicesListingResponse,
    ServiceCard,
} from './serviceList.types';
import { updatePaginationButtons } from '../pagination/pagination';
import axios from 'axios';
import saveOrRemoveFavourite from '../../../helpers/favourites';
import copyToClipboard from '../../../helpers/copyToClipboard';
import { IsFavourited } from '../../../helpers/isFavourite';
import IsUserLoggedIn from '../../../helpers/isUserLoggedIn';

const servicesListing = (document.querySelector('.service-list') as HTMLDivElement) || null;

if (servicesListing) {
    const grid = servicesListing.querySelector('.service-list__grid') as HTMLDivElement;
    const filterMobile = servicesListing.querySelector('.service-list__filter-mobile') as HTMLButtonElement;
    const nextPageButton = servicesListing.querySelector('.pagination__page-next') as HTMLButtonElement;
    const prevPageButton = servicesListing.querySelector('.pagination__page-prev') as HTMLButtonElement;
    const paginationDropdown = servicesListing.querySelector('.pagination__dropdown') as HTMLSelectElement;
    const paginationLast = servicesListing.querySelector('.pagination__page-last') as HTMLSelectElement;
    const paginationFirst = servicesListing.querySelector('.pagination__page-first') as HTMLSelectElement;
    const pagination = servicesListing.querySelector('.pagination') as HTMLDivElement;
    const radios: NodeListOf<HTMLInputElement> = servicesListing.querySelectorAll('.sort-filter__input');
    const servicesListingSearchInput = servicesListing.querySelector('.service-list__search-input') as HTMLInputElement;
    const servicesFilters = servicesListing.querySelector('.service-list__filters') as HTMLDivElement;
    const dropdownFilters: NodeListOf<HTMLDivElement> = servicesListing.querySelectorAll('.dropdown-filter');
    const currentCulture: string = document.documentElement.getAttribute('culture');
    const favouritedIds: number[] = JSON.parse(grid.getAttribute('data-ids'));

    let servicesCards: ServiceCard[] = [];
    let keyword = '';
    let educationalLevels = [];
    let serviceTypes = [];
    let beneficiaries = [];
    let sortBy = 1;
    let pageNumber = 1;
    let pageSize = +paginationDropdown.value;
    let totalPages = 0;
    let totalCount: number = 0;
    let hasNextPage = false;
    let hasPreviousPage = false;
    let buttonsToShow: number = 3;
    let isLoading = false;
    let clickedNumber = null;

    let serviceCardSettings: FetchServiceCardSettingsResponse = {
        cardButtonText: '',
        availableText: '',
        endText: '',
    };

    const fetchServiceCardSettings = async () => {
        try {
            const response = await axios.get<FetchServiceCardSettingsResponse>(
                `/api/services/cardsettings?currentCulture=${currentCulture}`,
            );

            serviceCardSettings = response.data;
        } catch (error) {
            console.error(error);
        }
    };

    const fetchServices = async (page = null) => {
        clickedNumber = page;
        pagination.classList.add('hidden');

        if (clickedNumber) {
            pageNumber = page;
        }

        const payload: FetchServicesListingPayload = {
            keyword,
            educationalLevels,
            serviceTypes,
            beneficiaries,
            sortBy,
            pageNumber,
            pageSize,
            currentCulture,
        };

        isLoading = true;
        disableInputs();

        try {
            // For developement:
            // const response = await axios.get<FetchNewsListingResponse>('https://localhost:44323/api/services', {
            //     params: payload,
            //     headers: {
            //         'Access-Control-Allow-Origin': '*'
            //     }
            // });

            const response = await axios.post<FetchServicesListingResponse>('/api/services', payload);

            servicesCards = response.data.items;

            if (servicesCards.length > 0) {
                pagination.classList.remove('hidden');
            }

            totalCount = response.data.totalCount;
            totalPages = response.data.totalPages;
            hasNextPage = response.data.hasNextPage;
            hasPreviousPage = response.data.hasPreviousPage;
        } catch (error) {
            console.error(error);
            enableInputs();
        } finally {
            updatePaginationButtons(
                totalPages,
                hasPreviousPage,
                hasNextPage,
                pageNumber,
                pageSize,
                totalCount,
                buttonsToShow,
                servicesCards,
                fetchServices,
            );
            isLoading = false;
            updateCards();
            enableInputs();
        }
    };

    const disableInputs = () => {
        pagination.classList.add('disabled');
        servicesFilters.classList.add('disabled');
    };

    const enableInputs = () => {
        pagination.classList.remove('disabled');
        servicesFilters.classList.remove('disabled');
    };

    const updateCards = () => {
        grid.innerHTML = '';

        servicesCards.forEach((card: ServiceCard) => {
            const cardElement = document.createElement('a');
            cardElement.className = 'service-list__card fade-in';
            cardElement.setAttribute('href', card.link);

            var _class = '';
            if (IsFavourited(card.id, favouritedIds)) {
                _class = 'active';
            }

            cardElement.innerHTML = `
                <p class='service-list__card-tag'>${
                    card.isAvailable ? serviceCardSettings.availableText : serviceCardSettings.endText
                }</p>
				<h3 class='service-list__card-title'>${card.title}</h3>
				<p class='service-list__card-text'>
						${card.description}
				</p>
				<div class='service-list__card-bottom flex flex--justify-between flex--align-center'>
                        <div class="service-list__link flex flex--align-center flex--justify-center">
                            ${serviceCardSettings.cardButtonText}
                            <img src="/assets/img/img/arrow-top-left-dark.svg" alt="arrow icon"
                                class="service-list__link-icon">
                        </div>
					<div class='service-list__card-buttons flex flex--align-center'>
                        ${
                            IsUserLoggedIn()
                                ? `<button class="button button--favourite-small favouriteButton service-list__button flex flex--justify-center flex--align-center ${_class}" data-type="service" data-id="${card.id}">
                                </button>`
                                : ''
                        }
						<button class="button  button--round-small shareButton service-list__button-share service-list__button  flex flex--justify-center flex--align-center">
								<img src="/assets/img/img/share-icon.svg" alt="share icon" class="button__image">
						</button>
					</div>
				</div>
				`;

            grid.appendChild(cardElement);
        });

        addCardListeners();
    };

    // Listeners
    paginationDropdown.addEventListener('change', () => {
        pageSize = +paginationDropdown.value;
        pageNumber = 1;
        fetchServices();
    });

    nextPageButton.addEventListener('click', () => {
        pageNumber++;

        fetchServices();
    });

    prevPageButton.addEventListener('click', () => {
        pageNumber--;

        fetchServices();
    });

    paginationFirst.addEventListener('click', () => {
        pageNumber = 1;
        fetchServices();
    });

    paginationLast.addEventListener('click', () => {
        pageNumber = totalPages;
        fetchServices();
    });

    let timeout = null;
    servicesListingSearchInput.addEventListener('input', (e) => {
        clearTimeout(timeout);

        timeout = setTimeout(function () {
            keyword = servicesListingSearchInput.value.trim();
            pageNumber = 1;
            fetchServices();
        }, 1000);
    });

    radios.forEach((radio) => {
        // 1 = Latest
        // 2 = A-Z
        // 3 = Z-A

        radio.addEventListener('change', () => {
            const checkedRadio = servicesListing.querySelector('input[name="sort-filter"]:checked') as HTMLInputElement;
            const checkedRadioValue = checkedRadio.value;
            sortBy = +checkedRadioValue;
            fetchServices();
        });
    });

    // Observe changes in dropdown filters
    dropdownFilters.forEach((dropdown, index) => {
        const optionsContainer = dropdown.querySelector('.dropdown-filter__button-container') as HTMLDivElement | null;

        if (optionsContainer) {
            const config: MutationObserverInit = { childList: true };

            const callback: MutationCallback = (mutationsList: MutationRecord[], observer: MutationObserver) => {
                for (const mutation of mutationsList) {
                    if (mutation.type === 'childList') {
                        switch (index) {
                            case 0:
                                const beneficiariesOptions = [];
                                Array.from(optionsContainer.children).forEach((element) => {
                                    beneficiariesOptions.push(element.textContent);
                                });
                                beneficiaries = beneficiariesOptions;
                                break;
                            case 1:
                                const serviceTypeOptions = [];
                                Array.from(optionsContainer.children).forEach((element) => {
                                    serviceTypeOptions.push(element.textContent);
                                });
                                serviceTypes = serviceTypeOptions;
                                break;
                            case 2:
                                const educationalLevelOptions = [];
                                Array.from(optionsContainer.children).forEach((element) => {
                                    educationalLevelOptions.push(element.textContent);
                                });
                                educationalLevels = educationalLevelOptions;
                                break;
                            default:
                                break;
                        }

                        pageNumber = 1;
                        fetchServices();
                    }
                }
            };

            const observer: MutationObserver = new MutationObserver(callback);

            observer.observe(optionsContainer, config);
        }
    });

    filterMobile.addEventListener('click', () => {
        const dropdowns = servicesListing.querySelector('.service-list__dropdowns') as HTMLDivElement;
        dropdowns.classList.toggle('service-list__dropdowns--active');
    });

    const initializeEventsList = async () => {
        await fetchServiceCardSettings();
        await fetchServices();
    };

    // Call the initialize function
    initializeEventsList();

    const addCardListeners = () => {
        const serviceListCards: NodeListOf<HTMLAnchorElement> = servicesListing.querySelectorAll('.service-list__card');

        if (serviceListCards.length > 0) {
            const cardButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.service-list__button');

            serviceListCards.forEach((card) => {
                card.addEventListener('click', (e) => {
                    const target = e.target as HTMLElement;

                    if (target.classList.contains('button') || target.classList.contains('button__image')) {
                        e.preventDefault();
                    }
                });
            });

            cardButtons.forEach((button) => {
                button.addEventListener('click', (e) => {
                    if (button.classList.contains('favouriteButton')) {
                        let t = button.getAttribute('data-type');
                        var id = Number(button.getAttribute('data-id'));
                        saveOrRemoveFavourite(t, id, button);
                    } else if (button.classList.contains('shareButton')) {
                        copyToClipboard(button);
                    }
                });
            });
        }
    };
}
