// const paginationComponent = document.querySelector(".pagination");

const direction: string = document.querySelector('html').getAttribute('dir');
const nextPageButton = document.querySelector('.pagination__page-next') as HTMLButtonElement;
const prevPageButton = document.querySelector('.pagination__page-prev') as HTMLButtonElement;
const paginationMultiEnd = document.querySelector('.pagination__page-multi-end') as HTMLSelectElement;
const paginationMultiStart = document.querySelector('.pagination__page-multi-start') as HTMLSelectElement;
const paginationLast = document.querySelector('.pagination__page-last') as HTMLSelectElement;
const paginationFirst = document.querySelector('.pagination__page-first') as HTMLSelectElement;
const paginationCurrent = document.querySelector('.pagination__current') as HTMLParagraphElement;
const paginationTotal = document.querySelector('.pagination__total') as HTMLParagraphElement;

let hasPreviousPage: boolean;
let hasNextPage: boolean;
let totalPages: number;
let pageNumber: number;
let totalCount: number;
let buttonsToShow: number = 3;
let pageCards = [];
let buttons = [];
let fetchEvents = null;

const getPages = (): number[] => {
    const startPage = pageNumber === 1 ? 1 : pageNumber - 1;
    return Array.from({ length: buttonsToShow }, (_, i) => startPage + i).filter((page) => page <= totalPages);
};

const removeOldButtons = () => {
    const oldButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.pagination__page-number');
    oldButtons.forEach((button) => button.remove());
};

const setPage = (page: number): void => {
    if (page < 1 || page > totalPages) return;

    removeOldButtons();
    pageNumber = page;
    createPaginationButtons();
    fetchEvents(pageNumber);
};

const updatePaginationButtons = (
    tot: number,
    hasPrevPage: boolean,
    hasNext: boolean,
    pageNum: number,
    pageSize: number,
    total: number,
    totButtons: number,
    cards,
    fetchResults: Function,
) => {
    hasPreviousPage = hasPrevPage;
    hasNextPage = hasNext;
    totalPages = tot;
    pageNumber = pageNum;
    totalCount = total;
    buttonsToShow = totButtons;
    pageCards = cards;
    fetchEvents = fetchResults;

    removeOldButtons();

    if (hasPreviousPage) {
        prevPageButton.removeAttribute('disabled');
    } else {
        prevPageButton.setAttribute('disabled', 'true');
    }

    if (hasNextPage) {
        nextPageButton.removeAttribute('disabled');
    } else {
        nextPageButton.setAttribute('disabled', 'true');
    }

    paginationLast.textContent = totalPages.toString();
    const offset = (pageNumber - 1) * pageSize + 1;
    const end = Math.min((pageNumber - 1) * pageSize + pageSize, totalCount);

    if (direction == 'rtl') {
        paginationCurrent.textContent = end.toString() + '-' + offset.toString();
    } else {
        paginationCurrent.textContent = offset.toString() + '-' + end.toString();
    }

    paginationTotal.textContent = totalCount.toString();

    createPaginationButtons();
};

const createPaginationButtons = (): void => {
    const pages = getPages();

    buttons = [];
    pages.forEach((page, i) => {
        const button = document.createElement('button');
        button.className = 'pagination__page pagination__page-number flex flex--align-center flex--justify-center';
        if (pageNumber === page) {
            button.classList.add('pagination__page--active');
        }
        button.textContent = page.toString();
        if (page === pageNumber) {
            button.classList.add('active');
        }

        button.addEventListener('click', () => setPage(page));

        buttons.push(button);
    });

    buttons.reverse().forEach((button) => {
        paginationMultiStart.insertAdjacentElement('afterend', button);
    });

    if (pageNumber === totalPages) {
        paginationMultiEnd.classList.add('hidden');
        paginationLast.classList.add('hidden');
    } else {
        paginationMultiEnd.classList.remove('hidden');
        paginationLast.classList.remove('hidden');
    }

    if (pageNumber > 2) {
        paginationFirst.classList.remove('hidden');
        paginationMultiStart.classList.remove('hidden');
    } else {
        paginationFirst.classList.add('hidden');
        paginationMultiStart.classList.add('hidden');
    }

    if (pageNumber === 1 && totalCount === 0) {
        paginationMultiEnd.classList.add('hidden');
        paginationLast.classList.add('disabled');
        paginationLast.classList.add('pagination__page--active');
        paginationLast.textContent = '1';
    }

    buttons.forEach((button) => {
        if (paginationLast.textContent === button.textContent) {
            paginationLast.classList.add('hidden');
            paginationMultiEnd.classList.add('hidden');
        }
    });
};

export { getPages, removeOldButtons, setPage, updatePaginationButtons, createPaginationButtons };
