import axios from 'axios';
import {
    FetchServiceTimelineTableColumnsNamesResponse,
    FetchTimeline2ItemsPayload,
    RowItem,
    TimePeriod,
} from './serviceTimelineThree.types';

const serviceTimelineThree = document.querySelector('.service-timeline-three') as HTMLElement;

if (serviceTimelineThree) {
    const timelineTableBody = serviceTimelineThree.querySelector(
        '.service-timeline-three__table-body',
    ) as HTMLTableSectionElement;
    const dropdownFilters: NodeListOf<HTMLDivElement> = serviceTimelineThree.querySelectorAll('.dropdown-filter');
    const parentNodeId = serviceTimelineThree.getAttribute('data-id');
    const currentCulture: string = document.documentElement.getAttribute('culture');
    const times: NodeListOf<HTMLElement> = serviceTimelineThree.querySelectorAll(
        '.service-timeline-three__table-content-time'
    );
    const serviceTimeline2Filters = serviceTimelineThree.querySelector(
        '.service-timeline-three__filters'
    ) as HTMLDivElement;

    if (timelineTableBody) {
        const timelineMobileTableBody = serviceTimelineThree.querySelector(
            '.service-timeline-three__table-mobile-body'
        ) as HTMLTableSectionElement;

        let serviceTimeline2ItemsResults: RowItem[] = [];
        let paths = [];
        let sections = [];
        let classes = [];
        let branches = [];
        let isLoading = false;
        const version = 2;

        let serviceTimelineTableColumnsNames: FetchServiceTimelineTableColumnsNamesResponse[] = [];

        const fetchServiceTimelineTableColumnsNames = async () => {
            try {
                const response = await axios.get<FetchServiceTimelineTableColumnsNamesResponse[]>(
                    `/api/services/timeline/columnsNames/${version}?currentCulture=${currentCulture}`
                );

                serviceTimelineTableColumnsNames = response.data;
            } catch (error) {
                console.error(error);
            }
        };

        const fetchServiceTimeline2TableData = async () => {
            const payload: FetchTimeline2ItemsPayload = {
                parentNodeId,
                paths,
                sections,
                classes,
                branches,
                currentCulture,
            };

            isLoading = true;
            disableInputs();
            try {
                let response;
                if (
                    (window.location.hostname === 'localhost' && window.location.port === '2000') ||
                    window.location.hostname === 'mawhiba-fractal.netlify.app'
                ) {
                    response = await axios.post<RowItem[]>(
                        'https://app-prd-umbracoportal-uaenorth-mawhiba-development.azurewebsites.net/api/services/timeline2',
                        payload
                    );
                } else {
                    response = await axios.post<RowItem[]>('/api/services/timeline2', payload);
                }
                serviceTimeline2ItemsResults = response.data;
            } catch (error) {
                console.error(error);
                enableInputs();
            } finally {
                isLoading = false;
                updateItems();
                enableInputs();
            }
        };

        const updateItems = () => {
            timelineTableBody.innerHTML = '';
            timelineMobileTableBody.innerHTML = '';

            serviceTimeline2ItemsResults.forEach((row: RowItem) => {
                const rowElement = document.createElement('tr');
                rowElement.className = 'service-timeline-three__table-row';

                rowElement.innerHTML = `
                <td class="service-timeline-three__table-data">
                    <div class="service-timeline-three__table-content flex flex--align-center">
                        <span class="service-timeline-three__table-content-title">${row.path}</span>
                    </div>
                </td>
                <td class="service-timeline-three__table-data">
                    <div class="service-timeline-three__table-content flex flex--align-center">
                        <span class="service-timeline-three__table-content-title">${row.section}</span>
                    </div>
                </td>
                <td class="service-timeline-three__table-data">
                    <div class="service-timeline-three__table-content flex flex--align-center">
                        <span class="service-timeline-three__table-content-title">${row.class}</span>
                    </div>
                </td>
                <td class="service-timeline-three__table-data">
                    <div class="service-timeline-three__table-content flex flex--align-center">
                        <span class="service-timeline-three__table-content-title">${row.branch}</span>
                    </div>
                </td>`;

                if (row.timePeriods !== null && row.timePeriods.length !== 0) {
                    row.timePeriods.forEach((timePeriod: TimePeriod) => {
                        const timePeriodElement = document.createElement('td');

                        timePeriodElement.className = 'service-timeline-three__table-data';

                        timePeriodElement.innerHTML = `
                        <div class="service-timeline-three__table-content flex flex--align-center">
                              <img src="/assets/img/img/icons/clock-grey.svg" alt="clock icon"
                                  class="service-timeline-three__table-icon" />
                              <div class="service-timeline-three__table-times flex flex--align-center">
                                  <span class="service-timeline-three__table-content-time">${timePeriod.startTime}</span>
                                  <span class="service-timeline-three__table-content-title">-</span>
                                  <span class="service-timeline-three__table-content-time">${timePeriod.endTime}</span>
                              </div>
                              <span class="service-timeline-three__table-content-title">${timePeriod.title}</span>
                          </div>`;

                        rowElement.appendChild(timePeriodElement);
                    });
                }

                addMobileRow(row);

                timelineTableBody.appendChild(rowElement);
            });
        };

        const addMobileRow = (row: RowItem) => {
            const path = serviceTimelineTableColumnsNames.find((item) => item.key.toLowerCase() === 'path');
            const section = serviceTimelineTableColumnsNames.find((item) => item.key.toLowerCase() === 'section');
            const educationClass = serviceTimelineTableColumnsNames.find((item) => item.key.toLowerCase() === 'class');
            const branch = serviceTimelineTableColumnsNames.find((item) => item.key.toLowerCase() === 'branch');
            const timePeriodName = serviceTimelineTableColumnsNames.find(
                (item) => item.key.toLowerCase() === 'time period'
            );

            timelineMobileTableBody.insertAdjacentHTML(
                'beforeend',
                `
                ${
                    path !== undefined
                        ? `<tr class="service-timeline-three__table-row">
                                <th class="service-timeline-three__table-header">${path.value}</th>
                                <td class="service-timeline-three__table-data">
                                    <div class="service-timeline-three__table-content flex flex--column">
                                        <span class="service-timeline-three__table-content-title">${row.path}</span>
                                    </div>
                                </td>
                            </tr>`
                        : ''
                }
        
                ${
                    section !== undefined
                        ? `<tr class="service-timeline-three__table-row">
                                <th class="service-timeline-three__table-header">${section.value}</th>
                                <td class="service-timeline-three__table-data">
                                    <div class="service-timeline-three__table-content flex flex--column">
                                        <span class="service-timeline-three__table-content-title">${row.section}</span>
                                    </div>
                                </td>
                            </tr>`
                        : ''
                }
        
                ${
                    educationClass !== undefined
                        ? `<tr class="service-timeline-three__table-row">
                                <th class="service-timeline-three__table-header">${educationClass.value}</th>
                                <td class="service-timeline-three__table-data">
                                    <div class="service-timeline-three__table-content flex flex--column">
                                        <span class="service-timeline-three__table-content-title">${row.class}</span>
                                    </div>
                                </td>
                            </tr>`
                        : ''
                }
        
                ${
                    branch !== undefined
                        ? `<tr class="service-timeline-three__table-row">
                                <th class="service-timeline-three__table-header">${branch.value}</th>
                                <td class="service-timeline-three__table-data">
                                    <div class="service-timeline-three__table-content flex flex--column">
                                        <span class="service-timeline-three__table-content-title">${row.branch}</span>
                                    </div>
                                </td>
                            </tr>`
                        : ''
                }`
            );

            if (timePeriodName !== undefined) {
                row.timePeriods.forEach((timePeriod: TimePeriod, index) => {
                    timelineMobileTableBody.insertAdjacentHTML(
                        'beforeend',
                        `<tr class="service-timeline-three__table-row">
                            <th class="service-timeline-three__table-header">${
                                index == 0 ? timePeriodName.value : ''
                            }</th>
                            <td class="service-timeline-three__table-data">
                                <div class="service-timeline-three__table-content flex flex--align-center service-timeline-three__table-content-icon flex--wrap">
                                    <img src="/assets/img/img/icons/clock-grey.svg" alt="clock icon" class="service-timeline-three__table-icon" />
                                    <div class="service-timeline-three__table-times flex flex--align-center">
                                        <span class="service-timeline-three__table-content-time">${
                                            timePeriod.startTime
                                        }</span>
                                        <span class="service-timeline-three__table-content-dash">-</span>
                                        <span class="service-timeline-three__table-content-time">${
                                            timePeriod.endTime
                                        }</span>
                                    </div>
                                    <span class="service-timeline-three__table-content-title">${timePeriod.title}</span>
                                </div>
                            </td>
                        </tr>`
                    );
                });
            }
        };

        const disableInputs = () => {
            serviceTimeline2Filters.classList.add('disabled');
        };

        const enableInputs = () => {
            serviceTimeline2Filters.classList.remove('disabled');
        };

        const getFormattedTime = (dateStr: string | null) => {
            if (!dateStr) return;

            const date = new Date(dateStr);
            const hour = String(date.getHours()).padStart(2, '0') as String;
            const minutes = String(date.getMinutes()).padStart(2, '0') as String;

            return `${hour}:${minutes}`;
        };

        // Observe changes in dropdown filters
        dropdownFilters.forEach((dropdown, index) => {
            const optionsContainer = dropdown.querySelector(
                '.dropdown-filter__button-container'
            ) as HTMLDivElement | null;

            if (optionsContainer) {
                const config: MutationObserverInit = { childList: true };

                const callback: MutationCallback = (mutationsList: MutationRecord[], observer: MutationObserver) => {
                    for (const mutation of mutationsList) {
                        if (mutation.type === 'childList') {
                            switch (index) {
                                case 0:
                                    const pathsOptions = [];
                                    Array.from(optionsContainer.children).forEach((element) => {
                                        pathsOptions.push(element.textContent);
                                    });
                                    paths = pathsOptions;
                                    break;
                                case 1:
                                    const sectionsOptions = [];
                                    Array.from(optionsContainer.children).forEach((element) => {
                                        sectionsOptions.push(element.textContent);
                                    });
                                    sections = sectionsOptions;
                                    break;
                                case 2:
                                    const classesOptions = [];
                                    Array.from(optionsContainer.children).forEach((element) => {
                                        classesOptions.push(element.textContent);
                                    });
                                    classes = classesOptions;
                                    break;
                                case 3:
                                    const branchesOptions = [];
                                    Array.from(optionsContainer.children).forEach((element) => {
                                        branchesOptions.push(element.textContent);
                                    });
                                    branches = branchesOptions;
                                    break;
                                default:
                                    break;
                            }

                            fetchServiceTimeline2TableData();
                        }
                    }
                };

                const observer: MutationObserver = new MutationObserver(callback);

                observer.observe(optionsContainer, config);
            }
        });

        times.forEach((time) => {
            time.textContent = getFormattedTime(time.textContent);
        });

        fetchServiceTimelineTableColumnsNames();
        fetchServiceTimeline2TableData();
    }
}
