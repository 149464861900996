import Glide from '@glidejs/glide';

const partnersComponent: HTMLDivElement = document.querySelector('.partners');

if (partnersComponent) {
    const partnersCarousels: NodeListOf<Element> = partnersComponent.querySelectorAll('.partners__carousel-inner');
    const direction: string = document.querySelector('html').getAttribute('dir');
    const partnersTabs: NodeListOf<HTMLButtonElement> = partnersComponent.querySelectorAll('.partners__tab');
    const partnersCarouselsContainer: NodeListOf<HTMLDivElement> =
        partnersComponent.querySelectorAll('.partners__carousel');
    let activeTab: number = 0;

    if (partnersCarousels.length > 0) {
        const partnersCarouselsArray = Array.from(partnersCarousels) || [];

        partnersCarouselsArray.forEach((item: HTMLElement) => {
            const numOfSlides = +item.getAttribute("data-count");

            const glideSettings = {
                type: 'slider',
                perView: 4,
                gap: 24,
                rewind: true,
                bound: true,
                hoverpause: true,
                direction: direction || 'ltr',
                breakpoints: {
                    1400: {
                        perView: 4,
                    },
                    1100: {
                        perView: 3,
                        gap: 16,
                    },
                    600: {
                        type: 'carousel',
                        perView: 1,
                        gap: 16,
                        focusAt: "center",
                        peek: {
                            before: 50,
                            after: 50
                        }
                    },
                    389: {
                        type: 'carousel',
                        perView: 1,
                        gap: 16,
                        focusAt: "center",
                        peek: {
                            before: 30,
                            after: 30
                        }
                    },
                },
            };

            const glide = new Glide(item, glideSettings);

            glide.mount();

            glide.on("resize", () => {
                generateBullets(bulletsContainer, bulletsToRender(numOfSlides, glide.settings.perView), glide)
            })

            const bulletsContainer = item.querySelector(".partners__bullets");

            generateBullets(bulletsContainer, bulletsToRender(numOfSlides, glide.settings.perView), glide);
        });
    }

    const resetAll = () => {
        partnersCarouselsContainer.forEach((carousel) => carousel.classList.remove('partners__carousel--active'));
        partnersTabs.forEach((tab) => tab.classList.remove('partners__tab--active'));
    };

    const showActiveItems = (activeTabNum: number) => {
        resetAll();
        partnersTabs[activeTabNum].classList.add('partners__tab--active');
        partnersCarouselsContainer[activeTabNum].classList.add('partners__carousel--active');
    };

    partnersTabs.forEach((tab, index: number) => {
        tab.addEventListener('click', () => {
            activeTab = index;
            showActiveItems(activeTab);
        });
    });

    showActiveItems(activeTab);
}

function bulletsToRender(num: number, perView: number) {
    const totalSlides = num;

    let amount = 0;

    if (totalSlides <= perView) {
        amount = 0
    } else {
        const steps = Math.ceil(totalSlides - perView);
        amount = +steps;
    }

    return amount;
}

function generateBullets(buttonsElement: Element, amount: number, glide) {
    buttonsElement.innerHTML = "";

    if (amount < 1) {
        return
    }

    for (let i = 0; i <= amount; i++) {
        const button = document.createElement("button");
        button.className = "glide__bullet partners__bullet";
        button.setAttribute("data-glide-dir", `=${i}`);

        buttonsElement.appendChild(button);

        button.addEventListener("click", () => {
            glide.go(`=${i}`)
        })
    }
}
