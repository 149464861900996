
google.charts.load('current', { packages: ['corechart'] });
const chartDiv = document.getElementById('curve_chart1');
google.charts.setOnLoadCallback(() => {
    const chartDiv = document.getElementById('curve_chart1');
    if (chartDiv) {
      if (getComputedStyle(chartDiv).display !== 'none') {
        drawChart(chartDiv);
      } else {
        observeVisibility(chartDiv);
      }
    }

    const chartDiv2 = document.getElementById('curve_chart2');
    if (chartDiv2) {
      if (getComputedStyle(chartDiv2).display !== 'none') {
        drawChart(chartDiv2);
      } else {
        observeVisibility(chartDiv2);
      }
    }

    const chartDiv3 = document.getElementById('curve_chart3');
    if (chartDiv3) {
      if (getComputedStyle(chartDiv3).display !== 'none') {
        drawChart(chartDiv3);
      } else {
        observeVisibility(chartDiv3);
      }
    }
  });

function drawChart(element: HTMLElement) {
    const chartData = element.getAttribute('data-chartdata');
    if (!chartData) {
        console.error('No data found in data-chartdata attribute');
        return;
    }

    const dataArray = JSON.parse(chartData);
    const data = google.visualization.arrayToDataTable(dataArray);
  
    const options: google.visualization.LineChartOptions = {
        hAxis: {
            gridlines: { color: 'transparent' },
            textPosition: 'none',
            baselineColor: 'transparent',
        },
        vAxis: {
            gridlines: { color: 'transparent' },
            textPosition: 'none',
            baselineColor: 'transparent',
        },
      curveType: 'function', // Smooth the lines
      legend: { position: 'none' },
      chartArea: { width: '100%', height: '100%' },
      series: {
        0: { color: '#6f6f91' }
      },
      lineWidth: 3, // Adjust the line width as needed
      tooltip: { trigger: 'none' } // Disable tooltips
    };
  
    const chart1 = new google.visualization.LineChart(element);
    google.visualization.events.addListener(chart1, 'ready', () => addShadowEffect1());
    chart1.draw(data, options);
  }

  function addShadowEffect1() {
    const svg = document.querySelector('#curve_chart1 svg')!;
    const defs = document.createElementNS('http://www.w3.org/2000/svg', 'defs');
    const filter = document.createElementNS('http://www.w3.org/2000/svg', 'filter');
    filter.setAttribute('id', 'shadow');
    filter.innerHTML = `
      <feDropShadow dx="0" dy="3" stdDeviation="3" flood-color="rgba(0, 0, 0, 0.3)" />
    `;
    defs.appendChild(filter);
    svg.appendChild(defs);
  
    const path = svg.querySelector('path')!;
    path.setAttribute('filter', 'url(#shadow)');
  }

  function addShadowEffect2() {
    const svg = document.querySelector('#curve_chart2 svg')!;
    const defs = document.createElementNS('http://www.w3.org/2000/svg', 'defs');
    const filter = document.createElementNS('http://www.w3.org/2000/svg', 'filter');
    filter.setAttribute('id', 'shadow');
    filter.innerHTML = `
      <feDropShadow dx="0" dy="3" stdDeviation="3" flood-color="rgba(0, 0, 0, 0.3)" />
    `;
    defs.appendChild(filter);
    svg.appendChild(defs);
  
    const path = svg.querySelector('path')!;
    path.setAttribute('filter', 'url(#shadow)');
  }

  function addShadowEffect3() {
    const svg = document.querySelector('#curve_chart3 svg')!;
    const defs = document.createElementNS('http://www.w3.org/2000/svg', 'defs');
    const filter = document.createElementNS('http://www.w3.org/2000/svg', 'filter');
    filter.setAttribute('id', 'shadow');
    filter.innerHTML = `
      <feDropShadow dx="0" dy="3" stdDeviation="3" flood-color="rgba(0, 0, 0, 0.3)" />
    `;
    defs.appendChild(filter);
    svg.appendChild(defs);
  
    const path = svg.querySelector('path')!;
    path.setAttribute('filter', 'url(#shadow)');
  }

  function observeVisibility(element: HTMLElement) {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'style' && getComputedStyle(element).display !== 'none') {
          drawChart(element);
        }
      });
    });
  
    observer.observe(element, { attributes: true });
  }

const initializeWebStatsComponent = (webStatsComponent: HTMLElement) => {
    const webStatsTabs: NodeListOf<HTMLElement> = webStatsComponent.querySelectorAll('.web-stats__tab');
    const webStatsContents: NodeListOf<HTMLElement> = webStatsComponent.querySelectorAll('.web-stats__stat');
    let activeTab = 0;

    if (webStatsTabs.length > 0) {
        // Tab logic
        const showSelectedItems = (activeTab: number) => {
            webStatsTabs.forEach((tab, index) => {
                tab.classList.remove('web-stats__tab--active');
                webStatsContents[index].style.display = 'none';
            });

            webStatsTabs[activeTab].classList.add('web-stats__tab--active');
            webStatsContents[activeTab].style.display = 'block';
        };

        const handleTabClick = (index: number) => {
            activeTab = index;
            showSelectedItems(activeTab);
        };

        webStatsTabs.forEach((tab, index) => {
            tab.addEventListener('click', () => handleTabClick(index));
        });

        showSelectedItems(activeTab);
    }
};

document.querySelectorAll('.web-stats').forEach((webStatsComponent) => {
    initializeWebStatsComponent(webStatsComponent as HTMLElement);
});
