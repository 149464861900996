import formatDateString from '../../../helpers/formatDate';
import convertTime from '../../../helpers/formatTime';
import { FetchServiceTimelineTableColumnsNamesResponse } from '../serviceTimelineThree/serviceTimelineThree.types';
import { TooltipItem, TimelineItem } from './serviceTimeline.types';
import axios from 'axios';

const serviceTimeline = document.querySelector('.service-timeline') as HTMLDivElement;
const direction: string = document.querySelector('html').getAttribute('dir');

const currentCulture: string = document.documentElement.getAttribute('culture');

if (serviceTimeline) {
    const serviceTimelineGrid = serviceTimeline.querySelector('.service-timeline__inner') as HTMLDivElement;
    const parentNodeId = serviceTimeline.getAttribute('data-id');
    const serviceTimelineTable = serviceTimeline.querySelector('.service-timeline-tables') as HTMLDivElement;

    let timelineItems: TimelineItem[] = [];

    if (serviceTimelineGrid) {
        const fetchServiceTimeline = async () => {
            try {
                let response;
                if (
                    (window.location.hostname === 'localhost' && window.location.port === '2000') ||
                    window.location.hostname === 'mawhiba-fractal.netlify.app'
                ) {
                    response = await axios.get<TimelineItem[]>(
                        `https://app-prd-umbracoportal-uaenorth-mawhiba-development.azurewebsites.net/api/services/timeline?nodeId=${parentNodeId}&currentCulture=${currentCulture}`
                    );
                } else {
                    response = await axios.get<TimelineItem[]>(
                        `/api/services/timeline?nodeId=${parentNodeId}&currentCulture=${currentCulture}`
                    );
                }
                timelineItems = response.data;
            } catch (error) {
                console.error(error);
            } finally {
                updateItems();
                initializeDates();
            }
        };
        const updateItems = () => {
            serviceTimelineGrid.innerHTML = '';

            timelineItems.forEach((item: TimelineItem) => {
                const itemElement = document.createElement('div');

                itemElement.className = 'service-timeline__item';

                itemElement.innerHTML = `
                <div class="service-timeline__content flex">
                  <div class="service-timeline__dates flex flex--align-center">
                    <div
                      class="service-timeline__date service-timeline__date-from flex flex--column flex--justify-center flex--align-center"
                      data-dateFrom="${item.startDate}">
                      <span class="service-timeline__date-month service-timeline__date-month-from"></span>
                      <span class="service-timeline__date-day service-timeline__date-day-from"></span>
                      <span class="service-timeline__date-year service-timeline__date-year-from"></span>
                    </div>
                    ${
                        item.endDate !== null
                            ? `<div
                                class="service-timeline__date service-timeline__date-to flex flex--column flex--justify-center flex--align-center"
                                data-dateTo="${item.endDate}">
                                <span class="service-timeline__date-month service-timeline__date-month-to"></span>
                                <span class="service-timeline__date-day service-timeline__date-day-to"></span>
                                <span class="service-timeline__date-year service-timeline__date-year-to"></span>
                              </div>`
                            : ''
                    }
                  </div>
                  <div class="service-timeline__info flex flex--column">
                    <div class="service-timeline__item-title flex flex--align-start flex--justify-between">
                      ${item.title}
                      ${
                          item.tooltips !== null && item.tooltips.length !== 0
                              ? `<button class="service-timeline__item-button">
                                  <img src="/assets/img/img/icons/info-circle.svg" alt="info circle icon"
                                    class="service-timeline__item-icon">
                                  <ul class="service-timeline__item-tooltip ">
                                    <div class="service-timeline__item-tooltip-inner flex flex--column">
                                      
                                    </div>
                                  </ul>
                                </button>`
                              : ''
                      }
                    </div>
                    <div class="service-timeline__item-text">
                      ${item.description}
                    </div>
                  </div>
                </div>`;

                if (item.tooltips !== null && item.tooltips.length !== 0) {
                    const tooltipList = itemElement.querySelector(
                        '.service-timeline__item-tooltip-inner',
                    ) as HTMLDivElement;

                    item.tooltips.forEach((tootlip: TooltipItem) => {
                        const tooltipElement = document.createElement('li');

                        tooltipElement.className = 'service-timeline__item-tooltip-row flex flex--align-center';

                        tooltipElement.innerHTML = `
                          <div class="service-timeline__item-tooltip-text">${tootlip.educationLevel}</div>
                          <div class="service-timeline__item-tooltip-date flex flex--align-center"
                            data-tooltipDate="${tootlip.date}">
                            <img src="/assets/img/img/icons/calendar-thick.svg" alt="calendar icon"
                              class="service-timeline__item-tooltip-icon">
                            <span class="service-timeline__item-tooltip-content">
                            </span>
                          </div>
                          <div class="service-timeline__item-tooltip-time flex flex--align-center"
                            data-tooltipTime="${tootlip.date}">
                            <img src="/assets/img/img/icons/clock-thick.svg" alt="clock icon"
                              class="service-timeline__item-tooltip-icon">
                            <span class="service-timeline__item-tooltip-timing">
                            </span>
                          </div>`;

                        tooltipList.appendChild(tooltipElement);
                    });
                }

                serviceTimelineGrid.appendChild(itemElement);
            });
        };

        const initializeDates = () => {
            const serviceTimelineItems: NodeListOf<HTMLDivElement> =
                serviceTimeline.querySelectorAll('.service-timeline__item');
            if (serviceTimelineItems.length === 1) {
                serviceTimelineItems[0].classList.add('service-timeline__item--single');
            }

            const setDates = (month: HTMLSpanElement, day: HTMLSpanElement, year: HTMLSpanElement, arr: string[]) => {
                month.textContent = arr[1];
                day.textContent = arr[0];
                year.textContent = arr[2];
            };

            serviceTimelineItems.forEach((item) => {
                const dateFrom = item
                    .querySelector('.service-timeline__date-from')
                    .getAttribute('data-dateFrom') as string;
                const dateTo = item.querySelector('.service-timeline__date-to')?.getAttribute('data-dateTo') as string;
                const monthFromElement = item.querySelector('.service-timeline__date-month-from') as HTMLSpanElement;
                const dayFromElement = item.querySelector('.service-timeline__date-day-from') as HTMLSpanElement;
                const yearFromElement = item.querySelector('.service-timeline__date-year-from') as HTMLSpanElement;
                const dateFromArray: string[] = formatDateString(dateFrom).split(' ');

                setDates(monthFromElement, dayFromElement, yearFromElement, dateFromArray);

                if (dateTo) {
                    const monthToElement = item.querySelector('.service-timeline__date-month-to') as HTMLSpanElement;
                    const dayToElement = item.querySelector('.service-timeline__date-day-to') as HTMLSpanElement;
                    const yearToElement = item.querySelector('.service-timeline__date-year-to') as HTMLSpanElement;
                    const dateToArray: string[] = formatDateString(dateTo).split(' ');

                    setDates(monthToElement, dayToElement, yearToElement, dateToArray);
                }

                const tooltipDateElements: NodeListOf<HTMLDivElement> = item.querySelectorAll(
                    '.service-timeline__item-tooltip-date'
                );
                const tooltipTimeElements: NodeListOf<HTMLDivElement> = item.querySelectorAll(
                    '.service-timeline__item-tooltip-time'
                );

                if (tooltipDateElements.length > 0) {
                    tooltipDateElements.forEach((tool) => {
                        const content = tool.querySelector(
                            '.service-timeline__item-tooltip-content'
                        ) as HTMLSpanElement;

                        content.textContent = formatDateString(tool.getAttribute('data-tooltipDate'));
                    });
                }

                if (tooltipTimeElements.length > 0) {
                    tooltipTimeElements.forEach((tool, index) => {
                        const timing = tool.querySelector('.service-timeline__item-tooltip-timing') as HTMLSpanElement;

                        if (timing) {
                            timing.textContent = convertTime(tool.getAttribute('data-tooltipTime'), direction);
                        }
                    });
                }
            });
        };

        fetchServiceTimeline();
    } else if (serviceTimelineTable) {
        const timelineTableBody = serviceTimelineTable.querySelector(
            '.service-timeline-tables__table-body'
        ) as HTMLTableSectionElement;

        if (timelineTableBody) {
            const timelineMobileTableBody = serviceTimelineTable.querySelector(
                '.service-timeline-tables__table-mobile-body'
            ) as HTMLTableSectionElement;
            const version = 1;

            let serviceTimelineTableColumnsNames: FetchServiceTimelineTableColumnsNamesResponse[] = [];

            const fetchServiceTimelineTableColumnsNames = async () => {
                try {
                    const response = await axios.get<FetchServiceTimelineTableColumnsNamesResponse[]>(
                        `/api/services/timeline/columnsNames/${version}?currentCulture=${currentCulture}`
                    );

                    serviceTimelineTableColumnsNames = response.data;
                } catch (error) {
                    console.error(error);
                }
            };

            const fetchServiceTimelineTableData = async () => {
                try {
                    let response;
                    if (
                        (window.location.hostname === 'localhost' && window.location.port === '2000') ||
                        window.location.hostname === 'mawhiba-fractal.netlify.app'
                    ) {
                        response = await axios.get<TimelineItem[]>(
                            `https://app-prd-umbracoportal-uaenorth-mawhiba-development.azurewebsites.net/api/services/timeline?nodeId=${parentNodeId}&currentCulture=${currentCulture}`
                        );
                    } else {
                        response = await axios.get<TimelineItem[]>(
                            `/api/services/timeline?nodeId=${parentNodeId}&currentCulture=${currentCulture}`
                        );
                    }
                    timelineItems = response.data;
                } catch (error) {
                    console.error(error);
                } finally {
                    updateTableRows();
                    initializeTable();
                }
            };

            const updateTableRows = () => {
                timelineTableBody.innerHTML = '';
                timelineMobileTableBody.innerHTML = '';

                timelineItems.forEach((row: TimelineItem, index) => {
                    const itemElement = document.createElement('tr');

                    itemElement.className = 'service-timeline-tables__table-row';

                    itemElement.innerHTML = `
                    <td class="service-timeline-tables__table-data">
                        <div class="service-timeline-tables__table-content flex flex--column flex--align-start">
                            <span class="service-timeline-tables__table-content-title">${index + 1}</span>
                        </div>
                    </td>
                    <td class="service-timeline-tables__table-data">
                        <div class="service-timeline-tables__table-content flex flex--column flex--align-start">
                            <span class="service-timeline-tables__table-content-title">${row.title}</span>
                            <span class="service-timeline-tables__table-content-text">${row.description}</span>
                        </div>
                    </td>
                    <td class="service-timeline-tables__table-data">
                        <div class="service-timeline-tables__table-content flex flex--column flex--align-start service-timeline-tables__table-content-icon">
                            <div class="service-timeline-tables__table-content-dates flex flex--align-center
                            ${
                                row.tooltips !== null && row.tooltips.length > 0
                                    ? `service-timeline-tables__table-content-dates-multi`
                                    : ''
                            }">
                            <div class="flex flex--align-center">
                                <img src="/assets/img/img/icons/calendar-grey.svg" alt="calendar icon"
                                    class="service-timeline-tables__table-icon" />
                                <span class="service-timeline-tables__table-content-date">${row.startDate}</span>
                            </div>
                            ${
                                row.endDate !== null
                                    ? `<span class="service-timeline-tables__table-content-note">-</span>
                                        <div class="flex flex--align-center">
                                            <span class="service-timeline-tables__table-content-date">${row.endDate}</span>
                                        </div>
                                        `
                                    : ''
                            }
    
                            ${
                                row.tooltips !== null && row.tooltips.length > 0
                                    ? `<img src="/assets/img/img/chevron-down.svg" alt="chevron icon" 
                                            class="service-timeline-tables__table-content-chevron" />`
                                    : ''
                            }
                            </div>
    
                            ${
                                row.tooltips !== null && row.tooltips.length > 0
                                    ? `<table class="service-timeline-tables__table-inner">
                                        <thead>
                                            <tr class="service-timeline-tables__table-row">
                                                <th class="service-timeline-tables__table-header">
                                                </th>
                                                <th class="service-timeline-tables__table-header">
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        </tbody>
                                    </table>`
                                    : ''
                            }
                        </div>
                    </td>
                    <td class="service-timeline-tables__table-data">
                        <div class="flex flex--align-center">
                            <img src="/assets/img/img/icons/clock-grey.svg" alt="clock icon"
                                class="service-timeline-tables__table-icon" />
                            <span class="service-timeline-tables__table-content-time">${row.startDate}</span>
                        </div>
                    </td>
                    `;

                    if (row.tooltips !== null && row.tooltips.length !== 0) {
                        const innerTableTbody = itemElement.querySelector(
                            '.service-timeline-tables__table-inner tbody'
                        ) as HTMLTableSectionElement;

                        row.tooltips.forEach((tooltip: TooltipItem) => {
                            const tooltipElement = document.createElement('tr');

                            tooltipElement.className = 'service-timeline-tables__table-row';

                            tooltipElement.innerHTML = `
                            <td class="service-timeline-tables__table-data">
                                <span class="service-timeline-tables__table-content-title">${tooltip.educationLevel}</span>
                            </td>
                            <td class="service-timeline-tables__table-data">
                                <div class="service-timeline-tables__table-dates flex flex--align-center">
                                    <div class="service-timeline-tables__table-date flex flex--align-center">
                                        <img src="/assets/img/img/icons/calendar-grey.svg" alt="calendar icon"
                                            class="service-timeline-tables__table-date-icon" />
                                        <span class="service-timeline-tables__table-content-date">${tooltip.date}</span>
                                    </div>
                                    <div class="service-timeline-tables__table-date flex flex--align-center">
                                        <img src="/assets/img/img/icons/clock-grey.svg" alt="clock icon"
                                            class="service-timeline-tables__table-date-icon" />
                                        <span class="service-timeline-tables__table-content-time">${tooltip.date}</span>
                                    </div>
                                </div>
                            </td>`;

                            innerTableTbody.appendChild(tooltipElement);
                        });
                    }

                    addMobileRow(row, index);

                    timelineTableBody.appendChild(itemElement);
                });
            };

            const addMobileRow = (row: TimelineItem, index) => {
                const step = serviceTimelineTableColumnsNames.find((item) => item.key.toLowerCase() === 'step');
                const mission = serviceTimelineTableColumnsNames.find((item) => item.key.toLowerCase() === 'mission');
                const timePeriodName = serviceTimelineTableColumnsNames.find(
                    (item) => item.key.toLowerCase() === 'time period'
                );

                timelineMobileTableBody.insertAdjacentHTML(
                    'beforeend',
                    `
                    ${
                        step !== undefined
                            ? `<tr class="service-timeline-tables__table-row">
                                    <th class="service-timeline-tables__table-header">${step.value}</th>
                                    <td class="service-timeline-tables__table-data">
                                        <div class="service-timeline-tables__table-content flex flex--column">
                                            <span class="service-timeline-tables__table-content-title">
                                                ${index + 1}
                                            </span>
                                        </div>
                                    </td>
                                </tr>`
                            : ''
                    }
            
                    ${
                        mission !== undefined
                            ? `<tr class="service-timeline-tables__table-row">
                                    <th class="service-timeline-tables__table-header">${mission.value}</th>
                                    <td class="service-timeline-tables__table-data">
                                        <div class="service-timeline-tables__table-content flex flex--column">
                                            <span class="service-timeline-tables__table-content-title">${row.title}</span>
                                            <span class="service-timeline-tables__table-content-text">${row.description}</span>
                                        </div>
                                    </td>
                                </tr>`
                            : ''
                    }
                    
                    ${
                        timePeriodName !== undefined
                            ? `<tr class="service-timeline-tables__table-row">
                                <th class="service-timeline-tables__table-header">${timePeriodName.value}</th>
                                <td class="service-timeline-tables__table-data">
                                    <div class="service-timeline-tables__table-content flex flex--column">
                                        <div class="service-timeline-tables__table-content-top flex flex--align-center">
                                            <img src="/assets/img/img/icons/calendar-grey.svg" alt="alt" class="service-timeline-tables__table-icon" />
                                            <span class="service-timeline-tables__table-content-title">
                                                ${formatDateString(row.startDate)}
                                                ${row.endDate !== null ? `- ${formatDateString(row.endDate)}` : ''}
                                            </span>
                                            <div class="service-timeline-tables__table-content flex flex--align-center service-timeline-tables__table-content-icon" style="margin-inline-start: auto;">
                                                <img src="/assets/img/img/icons/clock-grey.svg" alt="alt" class="service-timeline-tables__table-icon" />
                                                <span class="service-timeline-tables__table-content-time">${row.startDate}</span>
                                            </div>
                                        </div>

                                        ${
                                            row.tooltips !== null && row.tooltips.length !== 0
                                                ? ` <div class="service-timeline-tables__table-content-bottom flex flex--column">
                                                    ${row.tooltips.map(
                                                        (tooltip: TooltipItem) =>
                                                            `<div class="service-timeline-tables__table-content-row flex flex--wrap flex--align-center flex--justify-between">
                                                                <div class="service-timeline-tables__table-content-left">${tooltip.educationLevel}</div>
                                                                <div class="service-timeline-tables__table-content-right flex flex--wrap flex--align-center">
                                                                    <div class="service-timeline-tables__table-date flex flex--align-center">
                                                                    <img src="/assets/img/img/icons/calendar-grey.svg" alt="calendar icon"
                                                                        class="service-timeline-tables__table-date-icon" />
                                                                    <span class="service-timeline-tables__table-content-date">${tooltip.date}</span>
                                                                    </div>
                                                                    <div class="service-timeline-tables__table-date flex flex--align-center">
                                                                    <img src="/assets/img/img/icons/clock-grey.svg" alt="clock icon"
                                                                        class="service-timeline-tables__table-date-icon" />
                                                                    <span class="service-timeline-tables__table-content-time">${tooltip.date}</span>
                                                                    </div>
                                                                </div>
                                                            </div>`
                                                    )}
                                                </div>`
                                                : ''
                                        }
                                    </div>
                                </td>
                            </tr>
                            `
                            : ''
                    }`
                );
            };

            const initializeTable = () => {
                const dates: NodeListOf<HTMLElement> = serviceTimelineTable.querySelectorAll(
                    '.service-timeline-tables__table-content-date'
                );
                const times: NodeListOf<HTMLElement> = serviceTimelineTable.querySelectorAll(
                    '.service-timeline-tables__table-content-time'
                );
                const tableRows: NodeListOf<HTMLElement> = serviceTimelineTable.querySelectorAll(
                    '.service-timeline-tables__table-row'
                );
                const datesTriggers: NodeListOf<HTMLElement> = serviceTimelineTable.querySelectorAll(
                    '.service-timeline-tables__table-content-dates-multi'
                );
                const innerTables: NodeListOf<HTMLElement> = serviceTimelineTable.querySelectorAll(
                    '.service-timeline-tables__table-inner'
                );

                dates.forEach((date) => {
                    date.textContent = formatDateString(date.textContent);
                });

                times.forEach((time) => {
                    time.textContent = convertTime(time.textContent, direction);
                });

                tableRows.forEach((row) => {
                    const height = row.clientHeight as Number;

                    row.setAttribute('data-height', height.toString());
                });

                datesTriggers.forEach((trigger, index) => {
                    trigger.addEventListener('click', (e) => {
                        const innerTable = innerTables[index] as HTMLElement;
                        const icon = trigger.querySelector(
                            '.service-timeline-tables__table-content-chevron'
                        ) as HTMLElement;

                        if (innerTable.classList.contains('service-timeline-tables__table-inner--active')) {
                            innerTable.classList.remove('service-timeline-tables__table-inner--active');
                            icon.classList.remove('service-timeline-tables__table-content-chevron--rotate');

                            const activeRow = trigger.parentElement.parentElement.parentElement as HTMLElement;
                            const activeRowHeight = activeRow.getAttribute('data-height') as String;

                            activeRow.style.height = `${activeRowHeight}px`;
                            innerTable.style.top = '0';
                        } else {
                            innerTable.classList.add('service-timeline-tables__table-inner--active');
                            icon.classList.add('service-timeline-tables__table-content-chevron--rotate');

                            const activeRow = trigger.parentElement.parentElement.parentElement as HTMLElement;
                            const activeRowHeight = activeRow.clientHeight;
                            const innerTableHeight = innerTable.clientHeight;
                            const margin = 16;

                            activeRow.style.height = `${innerTableHeight + activeRowHeight + margin}px`;
                            innerTable.style.top = `${activeRowHeight}px`;
                        }
                    });
                });
            };

            fetchServiceTimelineTableColumnsNames();
            fetchServiceTimelineTableData();
        }
    }
}
