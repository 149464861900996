import { FetchVideosGalleryPayload, FetchVideosGalleryResponse, VideoCard } from './videoGallery.types';
import { updatePaginationButtons } from '../pagination/pagination';
import copyToClipboard from '../../../helpers/copyToClipboard';
import axios from 'axios';

const videosGallery: HTMLDivElement = document.querySelector('.video-gallery');

if (videosGallery) {
    const videosGrid = videosGallery.querySelector('.video-gallery__grid') as HTMLDivElement;
    const videosFilters = videosGallery.querySelector('.video-gallery__filters') as HTMLDivElement;
    const videosGallerySearchInput = videosGallery.querySelector('.input--search') as HTMLInputElement;
    const nextPageButton = videosGallery.querySelector('.pagination__page-next') as HTMLButtonElement;
    const prevPageButton = videosGallery.querySelector('.pagination__page-prev') as HTMLButtonElement;
    const paginationDropdown = videosGallery.querySelector('.pagination__dropdown') as HTMLSelectElement;
    const paginationLast = videosGallery.querySelector('.pagination__page-last') as HTMLSelectElement;
    const paginationFirst = videosGallery.querySelector('.pagination__page-first') as HTMLSelectElement;
    const pagination = videosGallery.querySelector('.pagination') as HTMLDivElement;
    const radios: NodeListOf<HTMLInputElement> = videosGallery.querySelectorAll('.sort-filter__input');
    const currentCulture: string = document.documentElement.getAttribute('culture');

    let videosCards: VideoCard[] = [];
    let keyword: string = '';
    let sortBy: number = 1;
    let pageNumber: number = 1;
    let pageSize: number = +paginationDropdown?.value;
    let totalPages: number = 0;
    let totalCount: number = 0;
    let hasNextPage: boolean = false;
    let hasPreviousPage: boolean = false;
    let buttonsToShow: number = 3;
    let isLoading: boolean = false;
    let clickedNumber = null;

    const fetchVideosGallery = async (page = null) => {
        clickedNumber = page;
        pagination?.classList.add('hidden');

        if (clickedNumber) {
            pageNumber = page;
        }

        const payload: FetchVideosGalleryPayload = {
            keyword,
            sortBy,
            pageNumber,
            pageSize,
            currentCulture,
        };

        isLoading = true;

        disableInputs();

        try {
            let response;
            if (
                (window.location.hostname === 'localhost' && window.location.port === '2000') ||
                window.location.hostname === 'mawhiba-fractal.netlify.app'
            ) {
                response = await axios.post<FetchVideosGalleryResponse>(
                    'https://app-prd-umbracoportal-uaenorth-mawhiba-development.azurewebsites.net/api/videosgallery',
                    payload
                );
            } else {
                response = await axios.post<FetchVideosGalleryResponse>('/api/videosgallery', payload);
            }
            videosCards = response.data.items;
            if (videosCards.length > 0) {
                pagination.classList.remove('hidden');
            }
            totalPages = response.data.totalPages;
            totalCount = response.data.totalCount;
            hasNextPage = response.data.hasNextPage;
            hasPreviousPage = response.data.hasPreviousPage;
        } catch (error) {
            console.error(error);
            enableInputs();
        } finally {
            updatePaginationButtons(
                totalPages,
                hasPreviousPage,
                hasNextPage,
                pageNumber,
                pageSize,
                totalCount,
                buttonsToShow,
                videosCards,
                fetchVideosGallery
            );
            isLoading = false;
            updateCards();
            enableInputs();
            CreatePlayEventListeners();
        }
    };

    const updateCards = () => {
        videosGrid.innerHTML = '';

        videosCards.forEach((card: VideoCard) => {
            const cardElement = document.createElement('a');

            cardElement.setAttribute('href', card.link);
            cardElement.className = 'video-inline-card flex flex--column';
            let formattedYoutubeVideoUrl: string;
            let videoContainer: string;

            if(card.youTubeID) {
                formattedYoutubeVideoUrl = `https://www.youtube.com/embed/${card.youTubeID}?enablejsapi=1;`
                cardElement.classList.add('video-inline-card--youtube');

                videoContainer = `
                    <div class='video-inline-card__video-container video-inline-card__video-container-youtube'>
                        <iframe id="player" width="385" height="332" src="${formattedYoutubeVideoUrl}" frameborder="0" allowfullscreen muted sandbox="allow-scripts allow-modals allow-same-origin allow-presentation"></iframe>
                        <img src="/assets/img/img/icons/play-button--white.svg" alt="play button" class='video-inline-card__play'>
                        <div class="video-inline-card__overlay"></div>
                    </div>
                `
            } else {
                videoContainer = `
                    <div class='video-inline-card__video-container'>
                        <video src='${card.videoURL}' playsinline disableRemotePlayback class='video-inline-card__video'></video>
                        <img src="/assets/img/img/icons/play-button--white.svg" alt="play button" class='video-inline-card__play'>
                        <div class="video-inline-card__overlay"></div>
                    </div>
                `
            }

            cardElement.innerHTML = `
            <div class='video-inline-card__tooltip'>${card.title}</div>
            <div class='video-inline-card__border flex flex--justify-between'>
                <a href="${card.videoURL}" draggable="false" download class="button button--round-small video-inline-card__download flex flex--justify-center flex--align-center">
                    <img src="/assets/img/img/icons/download--blue-bg.svg" alt="download icon" class="button__image">
                </a>
                <div class='video-inline-card__icons flex flex--align-center flex--justify-center'>
                    <div class="flex">
                        <img src='/assets/img/img/icons/eye.svg' alt='views icon' class='video-inline-card__eye' />
                        <span class='video-inline-card__count'>${card.views}</span>
                    </div>
                    <button
                        class="button button--round-small video-inline-card__icon flex flex--justify-center flex--align-center">
                        <img src="/assets/img/img/heart-icon.svg" alt="heart icon" class="button__image">
                    </button>
                    <button
                        class="button button--round-small video-inline-card__icon flex flex--justify-center flex--align-center">
                        <img src="/assets/img/img/share-icon.svg" alt="share icon" class="button__image">
                    </button>
                </div>
            </div>`;

            const border = cardElement.querySelector('.video-inline-card__border') as HTMLDivElement;
            border.insertAdjacentHTML('beforebegin', videoContainer);

            videosGrid.appendChild(cardElement);
        });
    };

    const CreatePlayEventListeners = () => {
        const videoCards = videosGallery.querySelectorAll('.video-inline-card');

        if (videoCards) {
            const mobileBreakpoint = '(max-width: 767px)';

            videoCards.forEach((card: Element) => {
                const video = card.querySelector('.video-inline-card__video') as HTMLVideoElement;
                const playButton = card.querySelector('.video-inline-card__play') as HTMLImageElement;

                if(card.classList.contains('video-inline-card--youtube')){
                    const playerElement = card.querySelector("#player") as HTMLIFrameElement;

                    if (playerElement) {
                        const player = new window['YT'].Player(playerElement, {
                            events: {
                                'onReady': (event) => {
                                    event.target.mute();

                                    card.addEventListener("mouseenter", () => {
                                        event.target.playVideo();
                                    });
    
                                    card.addEventListener("mouseleave", () => {
                                        event.target.pauseVideo();
                                        event.target.seekTo(0);
                                    });
                                }
                            }
                        });
                    }
                } else {
                    card.addEventListener('click', () => {
                        if (window.matchMedia(mobileBreakpoint).matches) {
                            if (video.paused) {
                                video.play();
                                playButton.style.opacity = '0';
                            } else {
                                video.pause();
                                playButton.style.opacity = '1';
                            }
                        }
                    });
    
                    card.addEventListener('mouseenter', () => {
                        if (!window.matchMedia(mobileBreakpoint).matches) {
                            video.play();
                        }
                    });
    
                    card.addEventListener('mouseleave', () => {
                        if (!window.matchMedia(mobileBreakpoint).matches) {
                            video.pause();
                            video.currentTime = 0;
                        }
                    });
                }
            });
        }
    }

    const disableInputs = () => {
        pagination?.classList.add('disabled');
        videosFilters?.classList.add('disabled');
    };

    const enableInputs = () => {
        pagination?.classList.remove('disabled');
        videosFilters.classList.remove('disabled');
    };

    paginationDropdown?.addEventListener('change', () => {
        pageSize = +paginationDropdown.value;
        pageNumber = 1;
        fetchVideosGallery();
    });

    nextPageButton?.addEventListener('click', () => {
        pageNumber++;

        fetchVideosGallery();
    });

    prevPageButton?.addEventListener('click', () => {
        pageNumber--;

        fetchVideosGallery();
    });

    paginationFirst?.addEventListener('click', () => {
        pageNumber = 1;
        fetchVideosGallery();
    });

    paginationLast?.addEventListener('click', () => {
        pageNumber = totalPages;
        fetchVideosGallery();
    });

    let timeout = null;
    videosGallerySearchInput?.addEventListener('input', (e) => {
        clearTimeout(timeout);

        timeout = setTimeout(function () {
            keyword = videosGallerySearchInput.value.trim();
            pageNumber = 1;
            fetchVideosGallery();
        }, 1000);
    });

    radios.forEach((radio) => {
        // 1 = latest
        // 2 = oldest
        // 3 = mostViewed

        radio.addEventListener('change', () => {
            const checkedRadio = videosGallery.querySelector('input[name="sort-filter"]:checked') as HTMLInputElement;
            const checkedRadioValue = checkedRadio.value;
            sortBy = +checkedRadioValue;
            fetchVideosGallery();
        });
    });

    fetchVideosGallery();

    const shareButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll(".shareButton");
    if (shareButtons && shareButtons.length > 0) {
        shareButtons.forEach(button => {
            button.addEventListener('click', (e) => {
                copyToClipboard(button);                 
            });   
        })
    }
}
