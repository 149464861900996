import {
    FetchGiftedCompetingSubListPayload,
    FetchGiftedCompetingSubListResponse,
    GiftedSubListItem,
    FetchGiftedSubListColumnsNamesResponse,
} from './giftedCompetingSubList.types';
import { updatePaginationButtons } from '../pagination/pagination';
import axios from 'axios';
import copyToClipboard from '../../../helpers/copyToClipboard';

const giftedSubListing = (document.querySelector('.gifted-sublist') as HTMLDivElement) || null;

if (giftedSubListing) {
    const parentNodeId = giftedSubListing.getAttribute('data-id');
    const tableBody = giftedSubListing.querySelector('.gifted-sublist__table-body') as HTMLDivElement;
    const mobileTableBody = giftedSubListing.querySelector('.gifted-sublist__table-mobile-body') as HTMLDivElement;
    const nextPageButton = giftedSubListing.querySelector('.pagination__page-next') as HTMLButtonElement;
    const prevPageButton = giftedSubListing.querySelector('.pagination__page-prev') as HTMLButtonElement;
    const paginationDropdown = giftedSubListing.querySelector('.pagination__dropdown') as HTMLSelectElement;
    const paginationLast = giftedSubListing.querySelector('.pagination__page-last') as HTMLSelectElement;
    const paginationFirst = giftedSubListing.querySelector('.pagination__page-first') as HTMLSelectElement;
    const pagination = giftedSubListing.querySelector('.pagination') as HTMLDivElement;
    const radios: NodeListOf<HTMLInputElement> = giftedSubListing.querySelectorAll('.sort-filter__input');
    const giftedSubListFilters = giftedSubListing.querySelector('.gifted-sublist__filters') as HTMLDivElement;
    const giftedSubListingSearchInput = giftedSubListing.querySelector(
        '.gifted-sublist__search-input'
    ) as HTMLInputElement;
    const dropdownFilters: NodeListOf<HTMLDivElement> = giftedSubListing.querySelectorAll('.dropdown-filter');
    const currentCulture: string = document.documentElement.getAttribute('culture');

    const singleDropdown = giftedSubListing.querySelector('.single-select') as HTMLSelectElement | null;
    const singleDropdownPlaceholder = Number(singleDropdown.getAttribute('data-placeholder'));
    const singleDropdownFilterTrigger: HTMLDivElement = giftedSubListing.querySelector('.single-dropdown-filter__trigger');

    let giftedSubListItems: GiftedSubListItem[] = [];
    let keyword = '';
    let educationDepartments = [];
    let positions = [];
    let projectFields = [];
    let olympiadTypes = [];
    let year = singleDropdownPlaceholder;
    singleDropdownFilterTrigger.innerHTML = singleDropdownPlaceholder.toString();
    let sortBy = 1;
    let pageNumber = 1;
    let pageSize = +paginationDropdown.value;
    let totalPages = 0;
    let totalCount: number = 0;
    let hasNextPage = false;
    let hasPreviousPage = false;
    let buttonsToShow: number = 3;
    let isLoading = false;
    let clickedNumber = null;

    let giftedSubListColumnsNames: FetchGiftedSubListColumnsNamesResponse[] = [];

    const fetchGiftedSubListColumnsNames = async () => {
        try {
            const response = await axios.get<FetchGiftedSubListColumnsNamesResponse[]>(
                `/api/giftedCompeting/columnsNames/${parentNodeId}?currentCulture=${currentCulture}`
            );

            giftedSubListColumnsNames = response.data;
        } catch (error) {
            console.error(error);
        }
    };

    const fetchGiftedSubList = async (page = null) => {
        clickedNumber = page;
        pagination.classList.add('hidden');

        if (clickedNumber) {
            pageNumber = page;
        }

        const payload: FetchGiftedCompetingSubListPayload = {
            parentNodeId,
            keyword,
            educationDepartments,
            positions,
            projectFields,
            olympiadTypes,
            year,
            sortBy,
            pageNumber,
            pageSize,
            currentCulture,
        };

        isLoading = true;
        disableInputs();

        try {
            let response;
            if (
                (window.location.hostname === 'localhost' && window.location.port === '2000') ||
                window.location.hostname === 'mawhiba-fractal.netlify.app'
            ) {
                response = await axios.post<FetchGiftedCompetingSubListResponse>(
                    'https://app-prd-umbracoportal-uaenorth-mawhiba-development.azurewebsites.net/api/giftedCompeting/sublist',
                    payload
                );
            } else {
                response = await axios.post<FetchGiftedCompetingSubListResponse>(
                    '/api/giftedCompeting/sublist',
                    payload
                );
            }
            giftedSubListItems = response.data.items;
            if (giftedSubListItems.length > 0) {
                pagination.classList.remove('hidden');
            }
            totalPages = response.data.totalPages;
            totalCount = response.data.totalCount;
            hasNextPage = response.data.hasNextPage;
            hasPreviousPage = response.data.hasPreviousPage;
        } catch (error) {
            console.error(error);
            enableInputs();
        } finally {
            updatePaginationButtons(
                totalPages,
                hasPreviousPage,
                hasNextPage,
                pageNumber,
                pageSize,
                totalCount,
                buttonsToShow,
                giftedSubListItems,
                fetchGiftedSubList
            );
            isLoading = false;
            updateItems();
            enableInputs();
            createModals();
        }
    };

    const disableInputs = () => {
        pagination.classList.add('disabled');
        giftedSubListFilters.classList.add('disabled');
    };

    const enableInputs = () => {
        pagination.classList.remove('disabled');
        giftedSubListFilters.classList.remove('disabled');
    };

    const updateItems = () => {
        tableBody.innerHTML = '';
        mobileTableBody.innerHTML = '';

        giftedSubListItems.forEach((item: GiftedSubListItem) => {
            const rowElement = document.createElement('tr');
            rowElement.className = 'gifted-sublist__table-row';

            rowElement.innerHTML = `
                ${
                    giftedSubListColumnsNames.some((item) => item.key.toLowerCase() === 'student name')
                        ? `<td class="gifted-sublist__table-data">
                                <div
                                    class="gifted-sublist__table-content flex flex--align-center ${
                                        item.studentImage !== null ? 'gifted-sublist__table-content-image' : ''
                                    }">
                                    ${
                                        item.studentImage !== null
                                            ? `<img src="${item.studentImage}" alt="student image" class="gifted-sublist__table-image" />`
                                            : ''
                                    }
                                    <span class="gifted-sublist__table-content-title">${item.studentName}</span>
                                </div>
                            </td>`
                        : ''
                }

                ${
                    giftedSubListColumnsNames.some((item) => item.key.toLowerCase() === 'speciality')
                        ? `<td class="gifted-sublist__table-data">
                                <div
                                    class="gifted-sublist__table-content flex flex--align-center">
                                    <span class="gifted-sublist__table-content-title">${
                                        item.speciality !== null ? item.speciality : '-'
                                    }</span>
                                </div>
                            </td>`
                        : ''
                }
                
                ${
                    giftedSubListColumnsNames.some((item) => item.key.toLowerCase() === 'project field')
                        ? `<td class="gifted-sublist__table-data">
                                <div
                                    class="gifted-sublist__table-content flex flex--align-center">
                                    <span class="gifted-sublist__table-content-title">${item.projectField}</span>
                                </div>
                            </td>`
                        : ''
                }

                ${
                    giftedSubListColumnsNames.some((item) => item.key.toLowerCase() === 'project title')
                        ? `<td class="gifted-sublist__table-data">
                                <div
                                    class="gifted-sublist__table-content flex flex--align-center">
                                    <span class="gifted-sublist__table-content-title">${item.projectTitle}</span>
                                </div>
                            </td>`
                        : ''
                }

                ${
                    giftedSubListColumnsNames.some((item) => item.key.toLowerCase() === 'education department')
                        ? `<td class="gifted-sublist__table-data">
                                <div
                                    class="gifted-sublist__table-content flex flex--align-center">
                                    <span class="gifted-sublist__table-content-title">${item.educationDepartment}</span>
                                </div>
                            </td>`
                        : ''
                }

                ${
                    giftedSubListColumnsNames.some((item) => item.key.toLowerCase() === 'olympiad')
                        ? `<td class="gifted-sublist__table-data">
                                <div
                                    class="gifted-sublist__table-content flex flex--align-center">
                                    <span class="gifted-sublist__table-content-title">${item.olympiad}</span>
                                </div>
                            </td>`
                        : ''
                }
                
                ${
                    giftedSubListColumnsNames.some((item) => item.key.toLowerCase() === 'grand prize')
                        ? `<td class="gifted-sublist__table-data">
                                <div
                                    class="gifted-sublist__table-content flex flex--align-center gifted-sublist__table-content-icon">
                                    ${
                                        item.position !== null
                                            ? `<img src="/assets/img/img/icons/medal-star.svg" alt="icon" class="gifted-sublist__table-icon">
                                                <span class="gifted-sublist__table-content-title">${item.position}</span>`
                                            : '<span class="gifted-sublist__table-content-title"></span>'
                                    }
                                    
                                </div>
                            </td>`
                        : ''
                }

                ${
                    giftedSubListColumnsNames.some((item) => item.key.toLowerCase() === 'special prize')
                        ? `<td class="gifted-sublist__table-data">
                                <div
                                    class="gifted-sublist__table-content flex flex--align-center ${
                                        item.specialPrize !== null ? '' : 'flex--justify-center'
                                    }">
                                    <span class="gifted-sublist__table-content-title">${
                                        item.specialPrize !== null ? item.specialPrize : '-'
                                    }</span>
                                </div>
                            </td>`
                        : ''
                }

                ${
                    giftedSubListColumnsNames.some((item) => item.key.toLowerCase() === 'success story')
                        ? `<td class="gifted-sublist__table-data">
                                <div class="gifted-sublist__table-content flex flex--align-center">
                                    ${
                                        item.successStoryVideoLink !== ''
                                            ? `<div
                                                class='modal modal--table'>
                                                    <button tabindex="0" class="modal__open">
                                                        <img tabindex="0" src='/assets/img/img/play-cricle.svg' alt='play button'
                                                            class='modal__icon modal__icon--table' />
                                                    </button>
                                                    <dialog class='modal__dialog'>
                                                        <div class='modal__inner'>
                                                            <div class='modal__top flex flex--justify-between flex--align-center'>
                                                                <div class='flex flex--justify-between flex--align-center'>
                                                                    <button class="button button--round-small modal__close flex flex--justify-center flex--align-center">
                                                                        <img src="/assets/img/img/icons/close.svg" alt="close icon" class="button__image">
                                                                    </button>
                                                                    <div class='modal__title' id='modal-title'>${item.studentName}</div>
                                                                </div>
                                                                <div class='modal__right flex flex--justify-between flex--align-center'>
                                                                <button class="button button--round-small modal__share flex flex--justify-center flex--align-center">
                                                                    <img src="/assets/img/img/share-icon.svg" alt="share icon" class="button__image">
                                                                </button>
                                                                </div>
                                                            </div>
                                                            <iframe class='modal__video' src='${item.successStoryVideoLink}' frameborder='0'
                                                            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                                                            allowFullScreen sandbox="allow-scripts allow-modals allow-same-origin allow-presentation"></iframe>
                                                        </div>
                                                    </dialog>
                                                </div>`
                                            : ''
                                    }
                                </div>
                            </td>`
                        : ''
                }`;

            addMobileRow(item);

            tableBody.appendChild(rowElement);
        });
    };

    const addMobileRow = (item: GiftedSubListItem) => {
        const studentName = giftedSubListColumnsNames.find((item) => item.key.toLowerCase() === 'student name');
        const speciality = giftedSubListColumnsNames.find((item) => item.key.toLowerCase() === 'speciality');
        const projectField = giftedSubListColumnsNames.find((item) => item.key.toLowerCase() === 'project field');
        const projectTitle = giftedSubListColumnsNames.find((item) => item.key.toLowerCase() === 'project title');
        const educationDepartment = giftedSubListColumnsNames.find(
            (item) => item.key.toLowerCase() === 'education department',
        );
        const olympiad = giftedSubListColumnsNames.find((item) => item.key.toLowerCase() === 'olympiad');
        const grandPrize = giftedSubListColumnsNames.find((item) => item.key.toLowerCase() === 'grand prize');
        const specialPrize = giftedSubListColumnsNames.find((item) => item.key.toLowerCase() === 'special prize');
        const successStory = giftedSubListColumnsNames.find((item) => item.key.toLowerCase() === 'success story');

        mobileTableBody.insertAdjacentHTML(
            'beforeend',
            `
        ${
            studentName !== undefined
                ? `<tr class="gifted-sublist__table-row">
                        <th class="gifted-sublist__table-header">${studentName.value}</th>
                        <td class="gifted-sublist__table-data">
                            <div class="gifted-sublist__table-content flex flex--align-center ${
                                item.studentImage !== null ? 'gifted-sublist__table-content-image' : ''
                            }">
                            ${
                                item.studentImage !== null
                                    ? `<img src="${item.studentImage}" alt="student image" class="gifted-sublist__table-image" />`
                                    : ''
                            }
                                <span class="gifted-sublist__table-content-title">${item.studentName}</span>
                            </div>
                        </td>
                    </tr>`
                : ''
        }

        ${
            speciality !== undefined
                ? `<tr class="gifted-sublist__table-row">
                        <th class="gifted-sublist__table-header">${speciality.value}</th>
                        <td class="gifted-sublist__table-data">
                            <div class="gifted-sublist__table-content flex flex--align-center">
                                <span class="gifted-sublist__table-content-title">${
                                    item.speciality !== null ? item.speciality : '-'
                                }</span>
                            </div>
                        </td>
                    </tr>`
                : ''
        }
        ${
            projectField !== undefined
                ? `<tr class="gifted-sublist__table-row">
                        <th class="gifted-sublist__table-header">${projectField.value}</th>
                        <td class="gifted-sublist__table-data">
                            <div class="gifted-sublist__table-content flex flex--align-center">
                                <span class="gifted-sublist__table-content-title">${item.projectField}</span>
                            </div>
                        </td>
                    </tr>`
                : ''
        }

        ${
            projectTitle !== undefined
                ? `<tr class="gifted-sublist__table-row">
                        <th class="gifted-sublist__table-header">${projectTitle.value}</th>
                        <td class="gifted-sublist__table-data">
                            <div class="gifted-sublist__table-content flex flex--align-center">
                                <span class="gifted-sublist__table-content-title">${item.projectTitle}</span>
                            </div>
                        </td>
                    </tr>`
                : ''
        }

        ${
            educationDepartment !== undefined
                ? `<tr class="gifted-sublist__table-row">
                        <th class="gifted-sublist__table-header">${educationDepartment.value}</th>
                        <td class="gifted-sublist__table-data">
                            <div class="gifted-sublist__table-content flex flex--align-center">
                                <span class="gifted-sublist__table-content-title">${item.educationDepartment}</span>
                            </div>
                        </td>
                    </tr>`
                : ''
        }

        ${
            olympiad !== undefined
                ? `<tr class="gifted-sublist__table-row">
                    <th class="gifted-sublist__table-header">${olympiad.value}</th>
                    <td class="gifted-sublist__table-data">
                        <div class="gifted-sublist__table-content flex flex--align-center">
                            <span class="gifted-sublist__table-content-title">${item.olympiad}</span>
                        </div>
                    </td>
                </tr>`
                : ''
        }

        ${
            grandPrize !== undefined
                ? `<tr class="gifted-sublist__table-row">
                        <th class="gifted-sublist__table-header">${grandPrize.value}</th>
                        <td class="gifted-sublist__table-data">
                            <div class="gifted-sublist__table-content flex flex--align-center gifted-sublist__table-content-icon">
                                <img src="/assets/img/img/icons/medal-star.svg" alt="alt" class="gifted-sublist__table-icon" />
                                <span class="gifted-sublist__table-content-title">${
                                    item.position !== null ? item.position : '-'
                                }</span>
                            </div>
                        </td>
                    </tr>`
                : ''
        }

        ${
            specialPrize !== undefined
                ? `<tr class="gifted-sublist__table-row">
                        <th class="gifted-sublist__table-header">${specialPrize.value}</th>
                        <td class="gifted-sublist__table-data">
                            <div class="gifted-sublist__table-content flex flex--align-center">
                                <span class="gifted-sublist__table-content-title">${
                                    item.speciality !== null ? item.speciality : '-'
                                }</span>
                            </div>
                        </td>
                    </tr>`
                : ''
        }

        ${
            successStory !== undefined
                ? `<tr class="gifted-sublist__table-row">
                        <th class="gifted-sublist__table-header">${successStory.value}</th>
                        <td class="gifted-sublist__table-data">
                            <div class="gifted-sublist__table-content flex flex--align-center">
                            ${
                                item.successStoryVideoLink !== ''
                                    ? `<div
                                        class='modal modal--table'>
                                            <button tabindex="0" class="modal__open">
                                                <img tabindex="0" src='/assets/img/img/play-cricle.svg' alt='play button'
                                                    class='modal__icon modal__icon--table' />
                                            </button>
                                            <dialog class='modal__dialog'>
                                                <div class='modal__inner'>
                                                    <div class='modal__top flex flex--justify-between flex--align-center'>
                                                        <div class='flex flex--justify-between flex--align-center'>
                                                            <button class="button button--round-small modal__close flex flex--justify-center flex--align-center">
                                                                <img src="/assets/img/img/icons/close.svg" alt="close icon" class="button__image">
                                                            </button>
                                                            <div class='modal__title' id='modal-title'>${item.studentName}</div>
                                                        </div>
                                                        <div class='modal__right flex flex--justify-between flex--align-center'>
                                                        <button class="button button--round-small modal__share flex flex--justify-center flex--align-center">
                                                            <img src="/assets/img/img/share-icon.svg" alt="share icon" class="button__image">
                                                        </button>
                                                        </div>
                                                    </div>
                                                    <iframe class='modal__video' src='${item.successStoryVideoLink}' frameborder='0'
                                                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                                                    allowFullScreen sandbox="allow-scripts, allow-modals, allow-same-origin allow-presentation"></iframe>
                                                </div>
                                            </dialog>
                                        </div>`
                                    : ''
                            }
                            </div>
                        </td>
                    </tr>`
                : ''
        }
        `
        );
    };

    const createModals = () => {
        const modals: NodeListOf<HTMLDivElement> = giftedSubListing.querySelectorAll('.modal');
        if (modals.length > 0) {
            modals?.forEach((modalContainer, index) => {
                const modalDialog = modalContainer.querySelector<HTMLDialogElement>('dialog');
                if (!modalDialog) return;

                const modalId = `modal-${index}`;
                const openButtonId = `open-button-${index}`;

                modalDialog.id = modalId;
                const openButton = modalContainer.querySelector<HTMLImageElement>('.modal__open');
                if (openButton) {
                    openButton.id = openButtonId;
                }

                const closeButton = modalDialog.querySelector<HTMLButtonElement>('.modal__close');

                document.getElementById(openButtonId)?.addEventListener('click', (event) => {
                    modalDialog.showModal();
                });

                closeButton?.addEventListener('click', (event) => {
                    modalDialog.close();
                });

                modalDialog.addEventListener('click', (event) => {
                    if (event.target === modalDialog) {
                        modalDialog.close();
                    }
                });
            });
        }
    };

    // Listeners
    paginationDropdown.addEventListener('change', () => {
        pageSize = +paginationDropdown.value;
        pageNumber = 1;
        fetchGiftedSubList();
    });

    singleDropdown.addEventListener('change', () => {
        const selected: HTMLSelectElement = singleDropdown.querySelector('option:checked');
        year = Number(selected.value);
        pageNumber = 1;
        fetchGiftedSubList();
    });

    singleDropdownFilterTrigger.addEventListener('keydown', (e) => {
        e.preventDefault();
    });

    nextPageButton.addEventListener('click', () => {
        pageNumber++;

        fetchGiftedSubList();
    });

    prevPageButton.addEventListener('click', () => {
        pageNumber--;

        fetchGiftedSubList();
    });

    paginationFirst.addEventListener('click', () => {
        pageNumber = 1;
        fetchGiftedSubList();
    });

    paginationLast.addEventListener('click', () => {
        pageNumber = totalPages;
        fetchGiftedSubList();
    });

    let timeout = null;
    giftedSubListingSearchInput.addEventListener('input', (e) => {
        clearTimeout(timeout);

        timeout = setTimeout(function () {
            keyword = giftedSubListingSearchInput.value.trim();
            pageNumber = 1;
            fetchGiftedSubList();
        }, 1000);
    });

    radios.forEach((radio) => {
        // 1 = Lowest
        // 2 = Highest

        radio.addEventListener('change', () => {
            const checkedRadio = giftedSubListing.querySelector(
                'input[name="sort-filter"]:checked'
            ) as HTMLInputElement;
            const checkedRadioValue = checkedRadio.value;
            sortBy = +checkedRadioValue;
            fetchGiftedSubList();
        });
    });

    // Observe changes in dropdown filters
    dropdownFilters.forEach((dropdown, index) => {
        const optionsContainer = dropdown.querySelector('.dropdown-filter__button-container') as HTMLDivElement | null;

        if (optionsContainer) {
            const config: MutationObserverInit = { childList: true };

            const callback: MutationCallback = (mutationsList: MutationRecord[], observer: MutationObserver) => {
                for (const mutation of mutationsList) {
                    if (mutation.type === 'childList') {
                        switch (index) {
                            case 0:
                                const educationDepartmentsOptions = [];
                                Array.from(optionsContainer.children).forEach((element) => {
                                    educationDepartmentsOptions.push(element.textContent);
                                });
                                educationDepartments = educationDepartmentsOptions;
                                break;
                            case 1:
                                const positionsOptions = [];
                                Array.from(optionsContainer.children).forEach((element) => {
                                    positionsOptions.push(element.textContent);
                                });
                                positions = positionsOptions;
                                break;
                            case 2:
                                const projectFieldsOptions = [];
                                Array.from(optionsContainer.children).forEach((element) => {
                                    projectFieldsOptions.push(element.textContent);
                                });
                                projectFields = projectFieldsOptions;
                                break;
                            case 3:
                                const olympiadTypesOptions = [];
                                Array.from(optionsContainer.children).forEach((element) => {
                                    olympiadTypesOptions.push(element.textContent);
                                });
                                olympiadTypes = olympiadTypesOptions;
                                break;
                            default:
                                break;
                        }

                        pageNumber = 1;
                        fetchGiftedSubList();
                    }
                }
            };

            const observer: MutationObserver = new MutationObserver(callback);

            observer.observe(optionsContainer, config);
        }
    });

    const initializeList = async () => {
        await fetchGiftedSubListColumnsNames();
        await fetchGiftedSubList();
    };

    // Call the initialize function
    initializeList();

    const shareButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.shareButton');
    if (shareButtons && shareButtons.length > 0) {
        shareButtons.forEach((button) => {
            button.addEventListener('click', (e) => {
                copyToClipboard(button);
            });
        });
    }
}
