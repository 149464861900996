import axios from 'axios';
import { QuickJoinDto, QuickJoinPayload } from './quickJoin.types';

const quickJoin = document.querySelector('.quick-join') as HTMLDivElement;

if (quickJoin) {
    const trigger = quickJoin.querySelector('.quick-join__trigger') as HTMLDivElement;
    const triggerText = quickJoin.querySelector('.quick-join__trigger-text') as HTMLSpanElement;
    const triggerIcon = quickJoin.querySelector('.quick-join__trigger-close') as HTMLImageElement;
    const form = quickJoin.querySelector('.quick-join__form') as HTMLFormElement;
    const nextButtons = quickJoin.querySelectorAll('.quick-join__next') as NodeListOf<HTMLButtonElement>;
    const closeButton = quickJoin.querySelector('.quick-join__close') as HTMLButtonElement;
    const submitButton = quickJoin.querySelector('.quick-join__submit') as HTMLButtonElement;
    const firstSection = quickJoin.querySelector('.quick-join__section-1') as HTMLDivElement;
    const secondSection = quickJoin.querySelector('.quick-join__section-2') as HTMLDivElement;
    const thirdSection = quickJoin.querySelector('.quick-join__section-3') as HTMLDivElement;
    const userEmail = quickJoin.querySelector('.quick-join__user') as HTMLSpanElement;

    // inputs
    const idInput = quickJoin.querySelector('.quick-join__id-input') as HTMLInputElement;
    const mobileNumberInput = quickJoin.querySelector('.country-dropdown__number') as HTMLInputElement;
    const emailInput = quickJoin.querySelector('.quick-join__email-input') as HTMLInputElement;
    const countryCode = quickJoin.querySelector('.country-dropdown__title') as HTMLSpanElement;
    const formidInput = quickJoin.querySelector('.quick-join-formid') as HTMLInputElement;

    // validations
    const classValidation = quickJoin.querySelector('.quick-join__classes-validation') as HTMLSpanElement;
    const programsValidation = quickJoin.querySelector('.quick-join__programs-validation') as HTMLSpanElement;
    const idValidation = quickJoin.querySelector('.quick-join__id-validation') as HTMLSpanElement;
    const mobileValidation = quickJoin.querySelector('.quick-join__mobile-validation') as HTMLSpanElement;
    const emailValidation = quickJoin.querySelector('.quick-join__email-validation') as HTMLSpanElement;

    // custom dropdowns
    const dropdownFilters: NodeListOf<HTMLDivElement> = firstSection.querySelectorAll('.dropdown-filter');
    const dropdownFilterTriggers: NodeListOf<HTMLDivElement> =
        firstSection.querySelectorAll('.dropdown-filter__trigger');
    const programsDropdown = firstSection.querySelector('.multi-select') as HTMLSelectElement | null;

    const singleDropdownFilter: HTMLDivElement = firstSection.querySelector('.single-dropdown-filter');
    const singleDropdownFilterTrigger: HTMLDivElement = firstSection.querySelector('.single-dropdown-filter__trigger');
    const singleDropdown = singleDropdownFilter.querySelector('.single-select') as HTMLSelectElement | null;
    const singleDropdownPlaceholder = singleDropdown.getAttribute('data-placeholder');

    const autocompleLists: NodeListOf<HTMLUListElement> = quickJoin.querySelectorAll('.multi-select');
    const singleAutocompleLists: NodeListOf<HTMLUListElement> = quickJoin.querySelectorAll('.single-select');
    const buttonContainers: NodeListOf<HTMLDivElement> = quickJoin.querySelectorAll(
        '.dropdown-filter__button-container'
    );
    const currentCulture: string = document.documentElement.getAttribute('culture');

    let _class = '';
    let programs = [];

    const resetForm = () => {
        firstSection.classList.remove('hidden');
        secondSection.classList.add('hidden');
        thirdSection.classList.add('hidden');

        idInput.value = '';
        mobileNumberInput.value = '';
        emailInput.value = '';

        buttonContainers.forEach((container) => {
            container.innerHTML = '';
        });
        programs = [];
        _class = '';
        autocompleLists.forEach((list) => {
            list.querySelectorAll('option').forEach((item) => {
                item.removeAttribute('selected');
            });
        });
        dropdownFilters.forEach((filter) => {
            filter.classList.add('disabled');
        });

        singleDropdownFilterTrigger.innerHTML = singleDropdownPlaceholder;
        singleAutocompleLists.forEach((list) => {
            list.querySelectorAll('option').forEach((item) => {
                item.removeAttribute('selected');
            });
        });

        userEmail.textContent = '';
    };

    const resetValidations = () => {
        classValidation.classList.add('hidden');
        programsValidation.classList.add('hidden');
        idValidation.classList.add('hidden');
        mobileValidation.classList.add('hidden');
        emailValidation.classList.add('hidden');
    };

    const isFormValid = () => {
        const tenDigitsPattern = /^\d{10}$/;
        const isIdValid = tenDigitsPattern.test(idInput.value);
        console.log(isIdValid)
        if (idInput.value === '' || !isIdValid) {
            idValidation.classList.remove('hidden');
        }

        if (mobileNumberInput.value === '') {
            mobileValidation.classList.remove('hidden');
        }

        if (emailInput.value === '') {
            emailValidation.classList.remove('hidden');
        }

        if (
            idInput.value !== '' &&
            isIdValid &&
            mobileNumberInput.value !== '' &&
            emailInput.value !== '' &&
            _class !== '' &&
            programs.length > 0 &&
            firstSection.classList.contains('hidden')
        ) {
            return true;
        }

        return false;
    };

    const toggleSlider = () => {
        resetForm();
        resetValidations();
        quickJoin.classList.toggle('quick-join--open');
        triggerIcon.classList.toggle('hidden');
    };

    const closeSlider = () => {
        quickJoin.classList.remove('quick-join--open');
        triggerIcon.classList.add('hidden');
        resetForm();
        resetValidations();
    };

    const resetProgramsDropdown = () => {
        buttonContainers.forEach((container) => {
            container.innerHTML = '';
        });
        programs = [];
        autocompleLists.forEach((list) => {
            list.querySelectorAll('option').forEach((item) => {
                item.removeAttribute('selected');
            });
        });
    };

    const getServices = async (className: string) => {
        try {
            const response = await axios.get<string[]>(
                `/api/quickjoin/services?class=${className}&currentCulture=${currentCulture}`
            );

            programsDropdown.length = 0;
            response.data.forEach((service) => {
                var opt = document.createElement('option');
                opt.value = service;
                opt.innerHTML = service;
                programsDropdown.appendChild(opt);
            });
        } catch (error) {
            console.error(error);
        }
    };

    closeButton.addEventListener('click', () => {
        closeSlider();
    });

    window.addEventListener('keydown', (e) => {
        if (e.key === 'Escape') {
            closeSlider();
        }
    });

    if(triggerText){
        window.addEventListener('scroll', function () {
            if (window.scrollY === 0) {
                triggerText.classList.remove('quick-join__trigger-text--hidden');
            } else {
                triggerText.classList.add('quick-join__trigger-text--hidden');
            }
        });
    }

    dropdownFilterTriggers.forEach((trigger) => {
        trigger.addEventListener('keydown', (e) => {
            e.preventDefault();
        });
    });

    singleDropdownFilterTrigger.addEventListener('keydown', (e) => {
        e.preventDefault();
    });

    singleDropdown.addEventListener('change', () => {
        const selected: HTMLSelectElement = singleDropdown.querySelector('option:checked');
        _class = selected.value;
        classValidation.classList.add('hidden');
        getServices(selected.value);
        resetProgramsDropdown();
        dropdownFilters.forEach((filter) => {
            filter.classList.remove('disabled');
        });
    });

    trigger.addEventListener('click', () => {
        toggleSlider();
    });

    trigger.addEventListener('keydown', (e) => {
        if (e.key === 'Enter') {
            toggleSlider();
        }
    });

    nextButtons.forEach((button, index) => {
        button.addEventListener('click', (e) => {
            e.preventDefault();

            if (index === 0) {
                if (_class === '') {
                    classValidation.classList.remove('hidden');
                }

                if (_class !== '' && programs.length < 1) {
                    programsValidation.classList.remove('hidden');
                }

                if (_class !== '' && programs.length > 0) {
                    firstSection.classList.add('hidden');
                    secondSection.classList.remove('hidden');
                    resetValidations();
                }
            }
        });
    });

    form.addEventListener('submit', (e) => {
        e.preventDefault();

        submitButton.setAttribute('disabled', 'true');

        if (isFormValid) {
            userEmail.textContent = emailInput.value;

            secondSection.classList.add('hidden');
            thirdSection.classList.remove('hidden');

            submitButton.removeAttribute('disabled');

            const dataObject: QuickJoinPayload = {
                class: _class,
                programs,
                idNumber: idInput.value,
                countryCode: countryCode.textContent,
                mobile: mobileNumberInput.value,
                email: emailInput.value,
                formid: formidInput.value,
            };

            console.log(dataObject);

            // Send data to the api
            SendData(dataObject);
        }
    });

    const SendData = async (dataObject: QuickJoinPayload) => {
        try {
            const response = await axios.post<QuickJoinDto>(`/api/quickjoin`, dataObject);

            console.log(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    // Observe changes in dropdown filters
    dropdownFilters.forEach((dropdown, index) => {
        const optionsContainer = dropdown.querySelector('.dropdown-filter__button-container') as HTMLDivElement | null;

        if (optionsContainer) {
            const config: MutationObserverInit = { childList: true };

            const callback: MutationCallback = (mutationsList: MutationRecord[], observer: MutationObserver) => {
                for (const mutation of mutationsList) {
                    if (mutation.type === 'childList') {
                        switch (index) {
                            case 0:
                                const arr2 = [];
                                Array.from(optionsContainer.children).forEach((element) => {
                                    arr2.push(element.textContent);
                                });
                                programsValidation.classList.add('hidden');
                                programs = arr2;
                                break;
                            default:
                                break;
                        }
                    }
                }
            };

            const observer: MutationObserver = new MutationObserver(callback);

            observer.observe(optionsContainer, config);
        }
    });
}
